import React, { useEffect, useState } from 'react';
import DiagnosticPoint from './components';
import { POINTS, POINTS_BASIC_INITIAL_STATE, METHODS_LIST, changeState } from './utils/util';
import { Button, DiagnosticDialogLoader } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

const DiagnosticModal = ({ getDiagnosticData, diagnosticData, currentWebsite, runDiagnostic, isDiagnosticLoader, currentWebsiteData }) => {

  useEffect(() => {
    getDiagnosticData({ websiteUuid: currentWebsite });
  }, []);

  const [failedPointsDetailsActive, setFailedPointsDetailsActive] = useState({
    ...POINTS_BASIC_INITIAL_STATE,
  });

  const [pointsToCheck, setPointsToCheck] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [currentDiagnosticsResults, setCurrentDiagnosticsResults] = useState(0);

  useEffect(() => {
    setPointsToCheck(METHODS_LIST);
  }, [diagnosticData?.detailed_diagnostic_data]);

  const handleSubmit = () => {
    const data = runDiagnostic(currentWebsite, pointsToCheck);
    setTimeout(() => {
      getDiagnosticData({ websiteUuid: currentWebsite, isOnlyUpdate: true });
    }, 200);
    return data;
  };

  useEffect(() => {
    setInterval(() => {
      diagnosticData?.diagnostic?.status === 'success' ? null : getDiagnosticData({ websiteUuid: currentWebsite, isOnlyUpdate: true });
    }, 10000);
  }, []);

  let page = 0;
  const getPreviousDiagnosticsResults = async () => {
    page = currentDiagnosticsResults;
    if (page + 2 < diagnosticData.diagonstics_ids.length) {
      setCurrentDiagnosticsResults(currentDiagnosticsResults + 1);
      await getDiagnosticData({ websiteUuid: currentWebsite, diagnosticId: diagnosticData.diagonstics_ids[page + 1].id });
    }
  };

  const getNextDiagnosticsResults = async () => {
    page = currentDiagnosticsResults;
    if (page > 0) {
      setCurrentDiagnosticsResults(currentDiagnosticsResults - 1);
      await getDiagnosticData({ websiteUuid: currentWebsite, diagnosticId: diagnosticData.diagonstics_ids[page - 1].id });
    }
  };

  return (
    <div className="diagnosticModal">
      <div className="diagnosticModal__heading">Szczegóły diagnostyki {currentWebsiteData?.name}</div>
      <div className="diagnosticModal__dashboard">
        <div className="diagnosticModal__dashboard--upperSection">
          <p className="diagnosticModal__dashboard--date">{}</p>
          <p className="diagnosticModal__dashboard--date">{diagnosticData?.diagnostic?.created_at}</p>
        </div>
        {diagnosticData?.detailed_diagnostic_data && !isDiagnosticLoader ? (
          POINTS(diagnosticData?.detailed_diagnostic_data).map((point, index) => (
            <DiagnosticPoint
              key={index}
              point={point}
              isDetailsActiveValue={failedPointsDetailsActive[point['name']]}
              failedPointsDetailsActive={failedPointsDetailsActive}
              setFailedPointsDetailsActive={setFailedPointsDetailsActive}
              changeState={changeState}
              diagnosticStatus={diagnosticData?.diagnostic?.status}
            />
          ))
        ) : (
          <DiagnosticDialogLoader />
        )}

        <div className="diagnosticModal__dashboard--btns">
          <Button
            type="button"
            classNames={`btn${
              currentDiagnosticsResults + 2 === diagnosticData?.diagonstics_ids?.length ? '--paginationDisabled' : '--blue'
            } previous`}
            iconImgSvg={SVG_TYPE.ARROW_RIGHT_LIGHT_DIAG}
            // label="Zobacz poprzednią"
            iconPlacement="left"
            iconClass="icon--rotate"
            onClick={() => getPreviousDiagnosticsResults()}
          />

          {pointsToCheck?.length === 0 ? (
            <Button type="button" classNames="btn--blue btn--runningDisabled" iconImgSvg={SVG_TYPE.ARROW_RIGHT_LIGHT_DIAG} label="Uruchom ponownie" />
          ) : (
            <Button
              type="button"
              classNames={`btn--blue diagnostics`}
              iconImgSvg={SVG_TYPE.ARROW_RIGHT_LIGHT_DIAG}
              label="Uruchom ponownie"
              disabled={isSubmitting}
              onClick={async () => {
                setIsSubmitting(true);
                await handleSubmit();
                setIsSubmitting(false);
              }}
            />
          )}

          <Button
            type="button"
            classNames={`btn${currentDiagnosticsResults === 0 ? '--paginationDisabled' : '--blue'} previous`}
            iconPlacement="left"
            iconImgSvg={SVG_TYPE.ARROW_RIGHT_LIGHT_DIAG}
            onClick={() => getNextDiagnosticsResults()}
          />
        </div>
      </div>
    </div>
  );
};

export default DiagnosticModal;
