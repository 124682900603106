import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

type P = {
  onClick?: () => void;
  state: boolean;
  disabled?: boolean;
  classNames?: string;
  key?: string;
  dataTip?: string;
  labelClassNames?: string;
  withoutLabel?: boolean;
  label?: string;
};

const Switcher = ({ state, disabled, onClick, classNames, labelClassNames, dataTip, withoutLabel, label }: P) => {
  return (
    <>
      <div className={`switcher ${disabled ? 'switcher--disabled':''}`}>
        <div className="wrapper">
          {withoutLabel ? null : (
            <p className={labelClassNames}>
              <FormattedMessage id={label} />
            </p>
          )}
          {dataTip && (
            <>
              <ReactTooltip place="right" type="light" effect="solid" className="tooltip tooltip--noShadow" />
              <p className="data-tip" data-tip={`${dataTip}`}>
                <SVG type={SVG_TYPE.INFORMATION} />
              </p>
            </>
          )}
        </div>
        <label className={`checkbox-wrapper ${classNames}`}>
          {state && <input type="checkbox" checked disabled={disabled} />}
          <small onClick={!disabled && onClick}></small>
        </label>
      </div>
    </>
  );
};

export default Switcher;
