import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Website.RUN_DIAGNOSTIC_INIT,
});

const runDiagnostic = (websiteUuid, diagnosticMethods) => async (dispatch) => {
  try {
    if (diagnosticMethods.length === 0) return false;
    let param = '';
    diagnosticMethods.forEach((method, index) => {
      param = `${index !== 0 ? param + ',' + method : method}`;
    });
    dispatch(init());
    await Connector.runDiagnostic(websiteUuid, param);
  } catch (err) {
    console.log(err);
  }
};

export default runDiagnostic;
