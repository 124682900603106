import * as Types from 'Shared/models/AddWebsiteModel';
import { AddWebsite } from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Shared/enums';

export type State = Types.AddWebsiteStore;

export const initialState: State = {
  formData: {
    data: {
      email: '',
      password: '',
      name: '',
      lang: '',
      password_strength: 'weak',
      ref: '',
      company: {
        name: '',
        vat_id: '',
        address: '',
        zip_code: '',
        city: '',
        country: '',
        phone_number: '',
      },
      url: '',
      website_uuid: '',
      plan: 'basic',
      ftp: {
        protocol: '',
        host: '',
        port: '',
        remote_path: '',
        username: '',
        password: '',
        website_uuid: '',
      },
    },
    status: REQUEST_STATUS.INIT,
    maxStep: 5,
    currentStep: 1,
  },
};

export function addWebsiteReducer(state: State = initialState, action) {
  switch (action.type) {
    case AddWebsite.USER_REGISTRATION_SUCCESS: {
      return {
        ...state,
      };
    }
    case AddWebsite.USER_REGISTRATION_FAILURE: {
      return {
        ...state,
      };
    }
    case AddWebsite.FILL_COMPANY_DATA_SUCCESS: {
      return {
        ...state,
      };
    }
    case AddWebsite.FILL_COMPANY_DATA_FAILURE: {
      return {
        ...state,
      };
    }
    case AddWebsite.ADD_FTP_SUCCESS: {
      return {
        ...state,
      };
    }
    case AddWebsite.ADD_FTP_FAILURE: {
      return {
        ...state,
      };
    }
    case AddWebsite.CHANGE_MAX_STEP: {
      return {
        ...state,
        formData: {
          ...state.formData,
          maxStep: action.payload,
        },
      };
    }
    case AddWebsite.CHANGE_CURRENT_STEP: {
      return {
        ...state,
        formData: {
          ...state.formData,
          currentStep: action.payload,
        },
      };
    }
    case AddWebsite.ADD_WEBSITE_SUCCESS: {
      const newState = {
        ...state.formData.data,
        website_uuid: action.payload.data.data.uuid,
      };
      sessionStorage.setItem('addWebsiteForm', JSON.stringify(newState));
      return {
        ...state,
        formData: {
          ...state.formData,
          data: {
            ...state.formData.data,
            website_uuid: action.payload.data.data.uuid,
          },
        },
      };
    }

    case AddWebsite.ADD_WEBISTE_FORM_CHANGE: {
      const newState = {
        ...state.formData.data,
        ...action.payload.data,
      };
      sessionStorage.setItem('addWebsiteForm', JSON.stringify(newState));
      return {
        ...state,
        formData: {
          ...state.formData,
          data: {
            ...state.formData.data,
            ...action.payload.data,
          },
        },
      };
    }

    case AddWebsite.ADD_WEBSITE_FORM_RESET: {
      sessionStorage.removeItem('addWebsiteForm');
      return {
        ...state,
        formData: initialState.formData,
      };
    }

    default: {
      return state;
    }
  }
}
