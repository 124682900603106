import { lazy, Suspense } from 'react';
const CheckSVG = lazy(() => import('Assets/Image/svg/CheckSVG'));
const QuestionMarksSVG = lazy(() => import('Assets/Image/svg/QuestionMarksSVG'));
const BubbleSVG = lazy(() => import('Assets/Image/svg/BubbleSVG'));
const CorgiSuitSVG = lazy(() => import('Assets/Image/svg/CorgiSuitSvg'));
const BlueCircleDesktopSVG = lazy(() => import('Assets/Image/svg/BlueCircleDesktopSVG'));
const BlueCircleHighSVG = lazy(() => import('Assets/Image/svg/BlueCircleHighSVG'));
const BuildingSVG = lazy(() => import('Assets/Image/svg/BuildingSVG'));
const CalendarSVG = lazy(() => import('Assets/Image/svg/CalendarSVG'));
const CheckedSVG = lazy(() => import('Assets/Image/svg/CheckedSVG'));
const ClientsSVG = lazy(() => import('Assets/Image/svg/ClientsSVG'));
const CreditCard2SVG = lazy(() => import('Assets/Image/svg/CreditCard2SVG'));
const ExitSVG = lazy(() => import('Assets/Image/svg/ExitSVG'));
const FlagAeSVG = lazy(() => import('Assets/Image/svg/FlagAeSVG'));
const FlagAtSVG = lazy(() => import('Assets/Image/svg/FlagAtSVG'));
const FlagAuSVG = lazy(() => import('Assets/Image/svg/FlagAuSVG'));
const FlagBeSVG = lazy(() => import('Assets/Image/svg/FlagBeSVG'));
const FlagBgSVG = lazy(() => import('Assets/Image/svg/FlagBgSVG'));
const FlagCaSVG = lazy(() => import('Assets/Image/svg/FlagCaSVG'));
const FlagChSVG = lazy(() => import('Assets/Image/svg/FlagChSVG'));
const FlagClSVG = lazy(() => import('Assets/Image/svg/FlagClSVG'));
const FlagCySVG = lazy(() => import('Assets/Image/svg/FlagCySVG'));
const FlagCzSVG = lazy(() => import('Assets/Image/svg/FlagCzSVG'));
const FlagDeSVG = lazy(() => import('Assets/Image/svg/FlagDeSVG'));
const FlagDkSVG = lazy(() => import('Assets/Image/svg/FlagDkSVG'));
const FlagEeSVG = lazy(() => import('Assets/Image/svg/FlagEeSVG'));
const FlagElSVG = lazy(() => import('Assets/Image/svg/FlagElSVG'));
const FlagEsSVG = lazy(() => import('Assets/Image/svg/FlagEsSVG'));
const FlagFiSVG = lazy(() => import('Assets/Image/svg/FlagFiSVG'));
const FlagFrabSVG = lazy(() => import('Assets/Image/svg/FlagFrabSVG'));
const FlagHrSVG = lazy(() => import('Assets/Image/svg/FlagHrSVG'));
const FlagHuSVG = lazy(() => import('Assets/Image/svg/FlagHuSVG'));
const FlagIeSVG = lazy(() => import('Assets/Image/svg/FlagIeSVG'));
const FlagIoSVG = lazy(() => import('Assets/Image/svg/FlagIoSVG'));
const FlagIrlSVG = lazy(() => import('Assets/Image/svg/FlagIrlSVG'));
const FlagIsSVG = lazy(() => import('Assets/Image/svg/FlagIsSVG'));
const FlagItSVG = lazy(() => import('Assets/Image/svg/FlagItSVG'));
const FlagJpSVG = lazy(() => import('Assets/Image/svg/FlagJpSVG'));
const FlagKrSVG = lazy(() => import('Assets/Image/svg/FlagKrSVG'));
const FlagLtSVG = lazy(() => import('Assets/Image/svg/FlagLtSVG'));
const FlagLuSVG = lazy(() => import('Assets/Image/svg/FlagLuSVG'));
const FlagLvSVG = lazy(() => import('Assets/Image/svg/FlagLvSVG'));
const FlagMtSVG = lazy(() => import('Assets/Image/svg/FlagMtSVG'));
const FlagMxSVG = lazy(() => import('Assets/Image/svg/FlagMxSVG'));
const FlagNlSVG = lazy(() => import('Assets/Image/svg/FlagNlSVG'));
const FlagNoSVG = lazy(() => import('Assets/Image/svg/FlagNoSVG'));
const FlagNzSVG = lazy(() => import('Assets/Image/svg/FlagNzSVG'));
const FlagPlSVG = lazy(() => import('Assets/Image/svg/FlagPlSVG'));
const FlagPtSVG = lazy(() => import('Assets/Image/svg/FlagPtSVG'));
const FlagPzSVG = lazy(() => import('Assets/Image/svg/FlagPzSVG'));
const FlagRoSVG = lazy(() => import('Assets/Image/svg/FlagRoSVG'));
const FlagRuSVG = lazy(() => import('Assets/Image/svg/FlagRuSVG'));
const FlagSaSVG = lazy(() => import('Assets/Image/svg/FlagSaSVG'));
const FlagSeSVG = lazy(() => import('Assets/Image/svg/FlagSeSVG'));
const FlagSkSVG = lazy(() => import('Assets/Image/svg/FlagSkSVG'));
const FlagSlSVG = lazy(() => import('Assets/Image/svg/FlagSlSVG'));
const FlagThSVG = lazy(() => import('Assets/Image/svg/FlagThSVG'));
const FlagTrSVG = lazy(() => import('Assets/Image/svg/FlagTrSVG'));
const FlagTwSVG = lazy(() => import('Assets/Image/svg/FlagTwSVG'));
const FlagUaSVG = lazy(() => import('Assets/Image/svg/FlagUaSVG'));
const FlagUsaSVG = lazy(() => import('Assets/Image/svg/FlagUsaSVG'));
const FlagXiSVG = lazy(() => import('Assets/Image/svg/FlagXiSVG'));
const FtpIconSVG = lazy(() => import('Assets/Image/svg/FtpIconSVG'));
const GiftSVG = lazy(() => import('Assets/Image/svg/GiftSVG'));
const GlobeSVG = lazy(() => import('Assets/Image/svg/GlobeSVG'));
const LockIcon2SVG = lazy(() => import('Assets/Image/svg/LockIcon2SVG'));
const LockIconSVG = lazy(() => import('Assets/Image/svg/LockIconSVG'));
const MailIcon2SVG = lazy(() => import('Assets/Image/svg/MailIcon2SVG'));
const MailIconSVG = lazy(() => import('Assets/Image/svg/MailIconSVG'));
const PadlockSVG = lazy(() => import('Assets/Image/svg/PadlockSVG'));
const PassedSVG = lazy(() => import('Assets/Image/svg/PassedSVG'));
const Paws2SVG = lazy(() => import('Assets/Image/svg/Paws2SVG'));
const PencilSVG = lazy(() => import('Assets/Image/svg/PencilSVG'));
const PersonSVG = lazy(() => import('Assets/Image/svg/PersonSVG'));
const PhpIconSVG = lazy(() => import('Assets/Image/svg/PhpIconSVG'));
const PhpVerSVG = lazy(() => import('Assets/Image/svg/PhpVerSVG'));
const PluginSVG = lazy(() => import('Assets/Image/svg/PluginSVG'));
const RedCircleSVG = lazy(() => import('Assets/Image/svg/RedCircleSVG'));
const SecuritySVG = lazy(() => import('Assets/Image/svg/SecuritySVG'));
const ShieldBuleCheckSVG = lazy(() => import('Assets/Image/svg/ShieldBuleCheckSVG'));
const ShuffleSVG = lazy(() => import('Assets/Image/svg/ShuffleSVG'));
const ThemeSVG = lazy(() => import('Assets/Image/svg/ThemeSVG'));
const WarningIconSVG = lazy(() => import('Assets/Image/svg/WarningIconSVG'));
const WarningSVG = lazy(() => import('Assets/Image/svg/WarningSVG'));
const WordpressSVG = lazy(() => import('Assets/Image/svg/WordpressSVG'));
const BackupSVG = lazy(() => import('Assets/Image/svg/BackupSVG'));
const BinSVG = lazy(() => import('Assets/Image/svg/BinSVG'));
const ChevronDownSVG = lazy(() => import('Assets/Image/svg/ChevronDownSVG'));
const ChevronLeftSVG = lazy(() => import('Assets/Image/svg/ChevronLeftSVG'));
const ChevronRightLightSVG = lazy(() => import('Assets/Image/svg/ChevronRightLightSVG'));
const ChevronRightSVG = lazy(() => import('Assets/Image/svg/ChevronRightSVG'));
const CorgiWithoutWoofSVG = lazy(() => import('Assets/Image/svg/CorgiWithoutWoofSVG'));
const DogpawsSVG = lazy(() => import('Assets/Image/svg/DogpawsSVG'));
const GroupFileSVG = lazy(() => import('Assets/Image/svg/GroupFileSVG'));
const HamburgerSVG = lazy(() => import('Assets/Image/svg/HamburgerSVG'));
const MastercardSVG = lazy(() => import('Assets/Image/svg/MastercardSVG'));
const PartnersSVG = lazy(() => import('Assets/Image/svg/PartnersSVG'));
const QuestSVG = lazy(() => import('Assets/Image/svg/QuestSVG'));
const SmsSVG = lazy(() => import('Assets/Image/svg/SmsSVG'));
const WpWarningSVG = lazy(() => import('Assets/Image/svg/WpWarningSVG'));
const AcceptGreenSVG = lazy(() => import('Assets/Image/svg/AcceptGreenSVG'));
const AcceptOrangeSVG = lazy(() => import('Assets/Image/svg/AcceptOrangeSVG'));
const AddPlusSVG = lazy(() => import('Assets/Image/svg/AddPlusSVG'));
const ArrowDownSVG = lazy(() => import('Assets/Image/svg/ArrowDownSVG'));
const ArrowLeftSVG = lazy(() => import('Assets/Image/svg/ArrowLeftSVG'));
const ArrowRightDarkSVG = lazy(() => import('Assets/Image/svg/ArrowRightDarkSVG'));
const ArrowRightLightDiagSVG = lazy(() => import('Assets/Image/svg/ArrowRightLightDiagSVG'));
const ArrowRightLightSVG = lazy(() => import('Assets/Image/svg/ArrowRightLightSVG'));
const ArrowRightSVG = lazy(() => import('Assets/Image/svg/ArrowRightSVG'));
const ArrowUpSVG = lazy(() => import('Assets/Image/svg/ArrowUpSVG'));
const BackupIconSVG = lazy(() => import('Assets/Image/svg/BackupIconSVG'));
const BrokenConnectedSVG = lazy(() => import('Assets/Image/svg/BrokenConnectedSVG'));
const BustWoofSVG = lazy(() => import('Assets/Image/svg/BustWoofSVG'));
const CallSVG = lazy(() => import('Assets/Image/svg/CallSVG'));
const CardSVG = lazy(() => import('Assets/Image/svg/CardSVG'));
const CheckerBackgroundSVG = lazy(() => import('Assets/Image/svg/CheckerBackgroundSVG'));
const CheckerSVG = lazy(() => import('Assets/Image/svg/CheckerSVG'));
const CircleSVG = lazy(() => import('Assets/Image/svg/CircleSVG'));
const CloseCircleFillSVG = lazy(() => import('Assets/Image/svg/CloseCircleFillSVG'));
const CloseCircleSVG = lazy(() => import('Assets/Image/svg/CloseCircleSVG'));
const CloseMenuSVG = lazy(() => import('Assets/Image/svg/CloseMenuSVG'));
const ClosePopupSVG = lazy(() => import('Assets/Image/svg/ClosePopupSVG'));
const CloseSVG = lazy(() => import('Assets/Image/svg/CloseSVG'));
const CommunicationSVG = lazy(() => import('Assets/Image/svg/CommunicationSVG'));
const CorgiBabySVG = lazy(() => import('Assets/Image/svg/CorgiBabySVG'));
const CorgiBandaSVG = lazy(() => import('Assets/Image/svg/CorgiBandaSVG'));
const CorgiCapeSVG = lazy(() => import('Assets/Image/svg/CorgiCapeSVG'));
const CorgiFlyGlassesSVG = lazy(() => import('Assets/Image/svg/CorgiFlyGlassesSVG'));
const CorgiFullBabySVG = lazy(() => import('Assets/Image/svg/CorgiFullBabySVG'));
const CorgiLostSVG = lazy(() => import('Assets/Image/svg/CorgiLostSVG'));
const CorgiPremiumCircleSVG = lazy(() => import('Assets/Image/svg/CorgiPremiumCircleSVG'));
const CorgiPremiumFlySVG = lazy(() => import('Assets/Image/svg/CorgiPremiumFlySVG'));
const CorgiPremiumReportSVG = lazy(() => import('Assets/Image/svg/CorgiPremiumReportSVG'));
const CorgiPremiumSVG = lazy(() => import('Assets/Image/svg/CorgiPremiumSVG'));
const CorgiProRotatedSVG = lazy(() => import('Assets/Image/svg/CorgiProRotatedSVG'));
const CorgiWoofTakSVG = lazy(() => import('Assets/Image/svg/CorgiWoofTakSVG'));
const CreditCardSVG = lazy(() => import('Assets/Image/svg/CreditCardSVG'));
const CrossedEyeSVG = lazy(() => import('Assets/Image/svg/CrossedEyeSVG'));
const DashboardSVG = lazy(() => import('Assets/Image/svg/DashboardSVG'));
const DomainCheckInProgrssSVG = lazy(() => import('Assets/Image/svg/DomainCheckInProgrssSVG'));
const DomainNotValidSVG = lazy(() => import('Assets/Image/svg/DomainNotValidSVG'));
const DomainValidSVG = lazy(() => import('Assets/Image/svg/DomainValidSVG'));
const DownloadSVG = lazy(() => import('Assets/Image/svg/DownloadSVG'));
const DropDownSVG = lazy(() => import('Assets/Image/svg/DropDownSVG'));
const EditSVG = lazy(() => import('Assets/Image/svg/EditSVG'));
const EmailSVG = lazy(() => import('Assets/Image/svg/EmailSVG'));
const ExclamationCircleSVG = lazy(() => import('Assets/Image/svg/ExclamationCircleSVG'));
const ExclamationSVG = lazy(() => import('Assets/Image/svg/ExclamationSVG'));
const EyeSVG = lazy(() => import('Assets/Image/svg/EyeSVG'));
const FailedSVG = lazy(() => import('Assets/Image/svg/FailedSVG'));
const FeatherDatabaseSVG = lazy(() => import('Assets/Image/svg/FeatherDatabaseSVG'));
const FeatherGoogleSVG = lazy(() => import('Assets/Image/svg/FeatherGoogleSVG'));
const FeatherLockSVG = lazy(() => import('Assets/Image/svg/FeatherLockSVG'));
const FeatherMailSVG = lazy(() => import('Assets/Image/svg/FeatherMailSVG'));
const FeatherSeetingsSVG = lazy(() => import('Assets/Image/svg/FeatherSeetingsSVG'));
const FileJpegSVG = lazy(() => import('Assets/Image/svg/FileJpegSVG'));
const FileJpgSVG = lazy(() => import('Assets/Image/svg/FileJpgSVG'));
const FilePngSVG = lazy(() => import('Assets/Image/svg/FilePngSVG'));
const FileSVG = lazy(() => import('Assets/Image/svg/FileSVG'));
const FileSvgSVG = lazy(() => import('Assets/Image/svg/FileSvgSVG'));
const FileUploadSVG = lazy(() => import('Assets/Image/svg/FileUploadSVG'));
const HelpSVG = lazy(() => import('Assets/Image/svg/HelpSVG'));
const HeroSectionBgSVG = lazy(() => import('Assets/Image/svg/HeroSectionBgSVG'));
const HeroSectionShieldSVG = lazy(() => import('Assets/Image/svg/HeroSectionShieldSVG'));
const InformationSVG = lazy(() => import('Assets/Image/svg/InformationSVG'));
const LockSVG = lazy(() => import('Assets/Image/svg/LockSVG'));
const LogoSVG = lazy(() => import('Assets/Image/svg/LogoSVG'));
const MainLogoSVG = lazy(() => import('Assets/Image/svg/MainLogoSVG'));
const MaterialSmsSVG = lazy(() => import('Assets/Image/svg/MaterialSmsSVG'));
const MonitorSVG = lazy(() => import('Assets/Image/svg/MonitorSVG'));
const OkaySVG = lazy(() => import('Assets/Image/svg/OkaySVG'));
const PacketsBasicNoBgSVG = lazy(() => import('Assets/Image/svg/PacketsBasicNoBgSVG'));
const PacketsBasicSVG = lazy(() => import('Assets/Image/svg/PacketsBasicSVG'));
const PacketsPremiumNoBgSVG = lazy(() => import('Assets/Image/svg/PacketsPremiumNoBgSVG'));
const PacketsPremiumSVG = lazy(() => import('Assets/Image/svg/PacketsPremiumSVG'));
const PacketsProNoBgSVG = lazy(() => import('Assets/Image/svg/PacketsProNoBgSVG'));
const PacketsProSVG = lazy(() => import('Assets/Image/svg/PacketsProSVG'));
const PagesSVG = lazy(() => import('Assets/Image/svg/PagesSVG'));
const PawsSVG = lazy(() => import('Assets/Image/svg/PawsSVG'));
const PaymentsSVG = lazy(() => import('Assets/Image/svg/PaymentsSVG'));
const PlusSVG = lazy(() => import('Assets/Image/svg/PlusSVG'));
const PolygonSVG = lazy(() => import('Assets/Image/svg/PolygonSVG'));
const PremiumWoofSVG = lazy(() => import('Assets/Image/svg/PremiumWoofSVG'));
const QuestionSVG = lazy(() => import('Assets/Image/svg/QuestionSVG'));
const RadioCheckedSVG = lazy(() => import('Assets/Image/svg/RadioCheckedSVG'));
const ReportIconSVG = lazy(() => import('Assets/Image/svg/ReportIconSVG'));
const ReportSVG = lazy(() => import('Assets/Image/svg/ReportSVG'));
const RightArrowBoldSVG = lazy(() => import('Assets/Image/svg/RightArrowBoldSVG'));
const RightArrowDashSVG = lazy(() => import('Assets/Image/svg/RightArrowDashSVG'));
const RightArrowSVG = lazy(() => import('Assets/Image/svg/RightArrowSVG'));
const RightArrowWhiteSVG = lazy(() => import('Assets/Image/svg/RightArrowWhiteSVG'));
const SettingsIconSVG = lazy(() => import('Assets/Image/svg/SettingsIconSVG'));
const SettingsSVG = lazy(() => import('Assets/Image/svg/SettingsSVG'));
const SitDownSVG = lazy(() => import('Assets/Image/svg/SitDownSVG'));
const StopSignSVG = lazy(() => import('Assets/Image/svg/StopSignSVG'));
const TimeBackupSVG = lazy(() => import('Assets/Image/svg/TimeBackupSVG'));
const TwistedArrowSVG = lazy(() => import('Assets/Image/svg/TwistedArrowSVG'));
const UpdateSVG = lazy(() => import('Assets/Image/svg/UpdateSVG'));
const UploadBackupSVG = lazy(() => import('Assets/Image/svg/UploadBackupSVG'));
const VisaSVG = lazy(() => import('Assets/Image/svg/VisaSVG'));
const WoofPremiumSVG = lazy(() => import('Assets/Image/svg/WoofPremiumSVG'));
const YouKnowSVG = lazy(() => import('Assets/Image/svg/YouKnowSVG'));
const Logout = lazy(() => import('Assets/Image/svg/LogoutSVG'));
const CorgiShield = lazy(() => import('Assets/Image/svg/CorgiShieldSVG'));
const MonitorIcon = lazy(() => import('Assets/Image/svg/MonitorIconSVG'));
const UpdateIcon = lazy(() => import('Assets/Image/svg/UpdateIconSVG'));
const ShieldIcon = lazy(() => import('Assets/Image/svg/ShieldIconSVG'));
const UploadIcon = lazy(() => import('Assets/Image/svg/UploadIconSVG'));
const FileIcon = lazy(() => import('Assets/Image/svg/FileIconSVG'));
const Paw1 = lazy(() => import('Assets/Image/svg/Paw1SVG'));
const Paw2 = lazy(() => import('Assets/Image/svg/Paw2SVG'));
const Paw3 = lazy(() => import('Assets/Image/svg/Paw3SVG'));
const Money = lazy(() => import('Assets/Image/svg/MoneySVG'));
const DesktopIcon = lazy(() => import('Assets/Image/svg/DesktopIconSVG'));
const SearchIcon = lazy(() => import('Assets/Image/svg/SearchIconSVG'));
const SmileIcon = lazy(() => import('Assets/Image/svg/SmileIconSVG'));
const AnnouncementIcon = lazy(() => import('Assets/Image/svg/AnnouncementIconSVG'));
const MagnetLogo = lazy(() => import('Assets/Image/svg/MagnetLogoSVG'));
const ReviewBackground = lazy(() => import('Assets/Image/svg/ReviewBackgroundSVG'));
const Apostrophe = lazy(() => import('Assets/Image/svg/ApostropheSVG'));
const CircleCheck = lazy(() => import('Assets/Image/svg/CircleCheckSVG'));
const FileDownload = lazy(() => import('Assets/Image/svg/FileDownloadSVG'));
const Facebook = lazy(() => import('Assets/Image/svg/FacebookSVG'));
const Instagram = lazy(() => import('Assets/Image/svg/InstagramSVG'));
const LinkedIn = lazy(() => import('Assets/Image/svg/LinkedinSVG'));
const Youtube = lazy(() => import('Assets/Image/svg/YoutubeSVG'));
const PlDoYouKnow = lazy(() => import('Assets/Image/svg/PlDoYouKnowSVG'));
const EnDoYouKnow = lazy(() => import('Assets/Image/svg/EnDoYouKnowSVG'));
const DeDoYouKnow = lazy(() => import('Assets/Image/svg/DeDoYouKnowSVG'));
const CorgiHeadphones = lazy(() => import('Assets/Image/svg/CorgiHeadphonesSVG'));
const QuestionMark = lazy(() => import('Assets/Image/svg/QuestionMarkSVG'));
const BackupCloud = lazy(() => import('Assets/Image/svg/BackupCloudSVG'));
const CopyrightSecourity = lazy(() => import('Assets/Image/svg/CopyrightSecouritySVG'));
const AutomaticUpdate = lazy(() => import('Assets/Image/svg/AutomaticUpdateSVG'));
const ConstantMonitoring = lazy(() => import('Assets/Image/svg/ConstantMonitoringSVG'));
const BigLoupe = lazy(() => import('Assets/Image/svg/BigLoupeSVG'));
const Bulb = lazy(() => import('Assets/Image/svg/BulbSVG'));
const Hosting = lazy(() => import('Assets/Image/svg/HostingSVG'));
const PhoneIcon = lazy(() => import('Assets/Image/svg/PhoneIconSVG'));
const LocationIcon = lazy(() => import('Assets/Image/svg/LocationIconSVG'));
const MailIcon3 = lazy(() => import('Assets/Image/svg/MailIcon3SVG'));
const CorgiGroupSVG = lazy(() => import('Assets/Image/svg/CorgiGroupSVG'));
const Money2SVG = lazy(() => import('Assets/Image/svg/Money2SVG'));
const RedDogLogoSVG = lazy(() => import('Assets/Image/svg/RedDogLogoSVG'));
const NataliaJagusLogoSVG = lazy(() => import('Assets/Image/svg/NataliaJagusLogoSVG'));
const RedWolfLogoSVG = lazy(() => import('Assets/Image/svg/RedWolfLogoSVG'));
const WebsiteKreacjeLogoSVG = lazy(() => import('Assets/Image/svg/WebsiteKreacjeLogoSVG'));
const DawebStudioSVG = lazy(() => import('Assets/Image/svg/DawebStudioSVG'));
const LinkSVG = lazy(() => import('Assets/Image/svg/LinkSVG'));
const ProfittoSVG = lazy(() => import('Assets/Image/svg/ProfittoSVG'));
const SoBrightSVG = lazy(() => import('Assets/Image/svg/SoBrightSVG'));
const SinglePageBackupSVG = lazy(() => import('Assets/Image/svg/SinglePageBackupSVG'));
const SinglePageBackupsSVG = lazy(() => import('Assets/Image/svg/SinglePageBackupsSVG'));
const SinglePageMonitoringSVG = lazy(() => import('Assets/Image/svg/SinglePageMonitoringSVG'));
const SinglePageBackupEnSVG = lazy(() => import('Assets/Image/svg/SinglePageBackupEnSVG'));
const SinglePageBackupsEnSVG = lazy(() => import('Assets/Image/svg/SinglePageBackupsENSVG'));
const SinglePageMonitoringEnSVG = lazy(() => import('Assets/Image/svg/SinglePageMonitoringEnSVG'));
const CorgiBiurkoSVG = lazy(() => import('Assets/Image/svg/CorgiBiurkoSVG'));

import * as React from 'react';
import { SVG_TYPE } from 'Shared/enums';

type P = {
  type?: any;
  className?: string;
  variant?: string;
};

const SVG = ({ type, className, variant }: P & { className?: string }) => {
  const getSvg = () => {
    switch (variant ? variant : type) {
      case SVG_TYPE.ACCEPT_GREEN:
        return <AcceptGreenSVG className={className} />;
      case SVG_TYPE.ACCEPT_ORANGE:
        return <AcceptOrangeSVG className={className} />;
      case SVG_TYPE.ADD_PLUS:
        return <AddPlusSVG className={className} />;
      case SVG_TYPE.ARROW_DOWN:
        return <ArrowDownSVG className={className} />;
      case SVG_TYPE.ARROW_LEFT:
        return <ArrowLeftSVG className={className} />;
      case SVG_TYPE.ARROW_RIGHT:
        return <ArrowRightSVG className={className} />;
      case SVG_TYPE.ARROW_RIGHT_DARK:
        return <ArrowRightDarkSVG className={className} />;
      case SVG_TYPE.ARROW_RIGHT_LIGHT:
        return <ArrowRightLightSVG className={className} />;
      case SVG_TYPE.ARROW_RIGHT_LIGHT_DIAG:
        return <ArrowRightLightDiagSVG className={className} />;
      case SVG_TYPE.ARROW_UP:
        return <ArrowUpSVG className={className} />;
      case SVG_TYPE.BACKUP_ICON:
        return <BackupIconSVG className={className} />;
      case SVG_TYPE.BROKEN_CONNECTED:
        return <BrokenConnectedSVG className={className} />;
      case SVG_TYPE.BUST_WOOF:
        return <BustWoofSVG className={className} />;
      case SVG_TYPE.CALL:
        return <CallSVG className={className} />;
      case SVG_TYPE.CARD:
        return <CardSVG className={className} />;
      // case SVG_TYPE.CHECKED:
      //   return <CheckedSVG className={className} />;
      case SVG_TYPE.CHECKER:
        return <CheckerSVG className={className} />;
      case SVG_TYPE.CHECKER_BACKGROUND:
        return <CheckerBackgroundSVG className={className} />;
      case SVG_TYPE.CIRCLE:
        return <CircleSVG className={className} />;
      case SVG_TYPE.CLOSE:
        return <CloseSVG className={className} />;
      case SVG_TYPE.CLOSE_CIRCLE:
        return <CloseCircleSVG className={className} />;
      case SVG_TYPE.CLOSE_CIRCLE_FILL:
        return <CloseCircleFillSVG className={className} />;
      case SVG_TYPE.CLOSE_MENU:
        return <CloseMenuSVG className={className} />;
      case SVG_TYPE.CLOSE_POPUP:
        return <ClosePopupSVG className={className} />;
      case SVG_TYPE.COMMUNICATION:
        return <CommunicationSVG className={className} />;
      case SVG_TYPE.CORGI_BABY:
        return <CorgiBabySVG className={className} />;
      case SVG_TYPE.CORGI_BANDA:
        return <CorgiBandaSVG className={className} />;
      case SVG_TYPE.CORGI_CAPE:
        return <CorgiCapeSVG className={className} />;
      case SVG_TYPE.CORGI_FLY_GLASSES:
        return <CorgiFlyGlassesSVG className={className} />;
      case SVG_TYPE.CORGI_FULL_BABY:
        return <CorgiFullBabySVG className={className} />;
      case SVG_TYPE.CORGI_LOST:
        return <CorgiLostSVG className={className} />;
      case SVG_TYPE.CORGI_PREMIUM:
        return <CorgiPremiumSVG className={className} />;
      case SVG_TYPE.CORGI_PREMIUM_CIRCLE:
        return <CorgiPremiumCircleSVG className={className} />;
      case SVG_TYPE.CORGI_PREMIUM_FLY:
        return <CorgiPremiumFlySVG className={className} />;
      case SVG_TYPE.CORGI_PREMIUM_REPORT:
        return <CorgiPremiumReportSVG className={className} />;
      case SVG_TYPE.CORGI_PRO_ROTATED:
        return <CorgiProRotatedSVG className={className} />;
      case SVG_TYPE.CORGI_WOOF_TAK:
        return <CorgiWoofTakSVG className={className} />;
      case SVG_TYPE.CREDIT_CARD:
        return <CreditCardSVG className={className} />;
      // case SVG_TYPE.CROSS:
      //   return <CrossSVG className={className} />;
      case SVG_TYPE.CROSSED_EYE:
        return <CrossedEyeSVG className={className} />;
      case SVG_TYPE.DASHBOARD:
        return <DashboardSVG className={className} />;
      case SVG_TYPE.DOMAIN_CHECK_IN_PROGRSS:
        return <DomainCheckInProgrssSVG className={className} />;
      case SVG_TYPE.DOMAIN_NOT_VALID:
        return <DomainNotValidSVG className={className} />;
      case SVG_TYPE.DOMAIN_VALID:
        return <DomainValidSVG className={className} />;
      case SVG_TYPE.DOWNLOAD:
        return <DownloadSVG className={className} />;
      case SVG_TYPE.DROP_DOWN:
        return <DropDownSVG className={className} />;
      case SVG_TYPE.EDIT:
        return <EditSVG className={className} />;
      case SVG_TYPE.EMAIL:
        return <EmailSVG className={className} />;
      case SVG_TYPE.EXCLAMATION:
        return <ExclamationSVG className={className} />;
      case SVG_TYPE.EXCLAMATION_CIRCLE:
        return <ExclamationCircleSVG className={className} />;
      case SVG_TYPE.EYE:
        return <EyeSVG className={className} />;
      case SVG_TYPE.FAILED:
        return <FailedSVG className={className} />;
      case SVG_TYPE.FEATHER_DATABASE:
        return <FeatherDatabaseSVG className={className} />;
      case SVG_TYPE.FEATHER_GOOGLE:
        return <FeatherGoogleSVG className={className} />;
      case SVG_TYPE.FEATHER_LOCK:
        return <FeatherLockSVG className={className} />;
      case SVG_TYPE.FEATHER_MAIL:
        return <FeatherMailSVG className={className} />;
      case SVG_TYPE.FEATHER_SEETINGS:
        return <FeatherSeetingsSVG className={className} />;
      case SVG_TYPE.FILE:
        return <FileSVG className={className} />;
      case SVG_TYPE.FILE_JPEG:
        return <FileJpegSVG className={className} />;
      case SVG_TYPE.FILE_JPG:
        return <FileJpgSVG className={className} />;
      case SVG_TYPE.FILE_PNG:
        return <FilePngSVG className={className} />;
      case SVG_TYPE.FILE_SVG:
        return <FileSvgSVG className={className} />;
      case SVG_TYPE.FILE_UPLOAD:
        return <FileUploadSVG className={className} />;
      case SVG_TYPE.HELP:
        return <HelpSVG className={className} />;
      case SVG_TYPE.HERO_SECTION_BG:
        return <HeroSectionBgSVG className={className} />;
      case SVG_TYPE.HERO_SECTION_SHIELD:
        return <HeroSectionShieldSVG className={className} />;
      case SVG_TYPE.INFORMATION:
        return <InformationSVG className={className} />;
      case SVG_TYPE.LOCK:
        return <LockSVG className={className} />;
      case SVG_TYPE.LOGO:
        return <LogoSVG className={className} />;
      case SVG_TYPE.MAIN_LOGO:
        return <MainLogoSVG className={className} />;
      case SVG_TYPE.MATERIAL_SMS:
        return <MaterialSmsSVG className={className} />;
      case SVG_TYPE.MONITOR:
        return <MonitorSVG className={className} />;
      case SVG_TYPE.OKAY:
        return <OkaySVG className={className} />;
      case SVG_TYPE.PACKETS_BASIC:
        return <PacketsBasicSVG className={className} />;
      case SVG_TYPE.PACKETS_BASIC_NO_BG:
        return <PacketsBasicNoBgSVG className={className} />;
      case SVG_TYPE.PACKETS_PREMIUM:
        return <PacketsPremiumSVG className={className} />;
      case SVG_TYPE.PACKETS_PREMIUM_NO_BG:
        return <PacketsPremiumNoBgSVG className={className} />;
      case SVG_TYPE.PACKETS_PRO:
        return <PacketsProSVG className={className} />;
      case SVG_TYPE.PACKETS_PRO_NO_BG:
        return <PacketsProNoBgSVG className={className} />;
      case SVG_TYPE.PAGES:
        return <PagesSVG className={className} />;
      case SVG_TYPE.PAWS:
        return <PawsSVG className={className} />;
      case SVG_TYPE.PAYMENTS:
        return <PaymentsSVG className={className} />;
      case SVG_TYPE.PLUS:
        return <PlusSVG className={className} />;
      case SVG_TYPE.POLYGON:
        return <PolygonSVG className={className} />;
      case SVG_TYPE.PREMIUM_WOOF:
        return <PremiumWoofSVG className={className} />;
      case SVG_TYPE.QUESTION:
        return <QuestionSVG className={className} />;
      case SVG_TYPE.RADIO_CHECKED:
        return <RadioCheckedSVG className={className} />;
      case SVG_TYPE.REPORT:
        return <ReportSVG className={className} />;
      case SVG_TYPE.REPORT_ICON:
        return <ReportIconSVG className={className} />;
      case SVG_TYPE.RIGHT_ARROW:
        return <RightArrowSVG className={className} />;
      case SVG_TYPE.RIGHT_ARROW_BOLD:
        return <RightArrowBoldSVG className={className} />;
      case SVG_TYPE.RIGHT_ARROW_DASH:
        return <RightArrowDashSVG className={className} />;
      // case SVG_TYPE.RIGHT_ARROW_LIGHT:
      //   return <RightArrowLightSVG className={className} />;
      case SVG_TYPE.RIGHT_ARROW_WHITE:
        return <RightArrowWhiteSVG className={className} />;
      case SVG_TYPE.SETTINGS:
        return <SettingsSVG className={className} />;
      case SVG_TYPE.SETTINGS_ICON:
        return <SettingsIconSVG className={className} />;
      case SVG_TYPE.SIT_DOWN:
        return <SitDownSVG className={className} />;
      case SVG_TYPE.STOP_SIGN:
        return <StopSignSVG className={className} />;
      case SVG_TYPE.TIME_BACKUP:
        return <TimeBackupSVG className={className} />;
      case SVG_TYPE.TWISTED_ARROW:
        return <TwistedArrowSVG className={className} />;
      case SVG_TYPE.UPLOAD_BACKUP:
        return <UploadBackupSVG className={className} />;
      case SVG_TYPE.UPDATE:
        return <UpdateSVG className={className} />;
      case SVG_TYPE.VISA:
        return <VisaSVG className={className} />;
      case SVG_TYPE.WOOF_PREMIUM:
        return <WoofPremiumSVG className={className} />;
      case SVG_TYPE.YOU_KNOW:
        return <YouKnowSVG className={className} />;
      case SVG_TYPE.LOGOUT:
        return <Logout className={className} />;
      case SVG_TYPE.CORGI_SHIELD:
        return <CorgiShield className={className} />;
      case SVG_TYPE.MONITOR_ICON:
        return <MonitorIcon className={className} />;
      case SVG_TYPE.UPDATE_ICON:
        return <UpdateIcon className={className} />;
      case SVG_TYPE.SHIELD_ICON:
        return <ShieldIcon className={className} />;
      case SVG_TYPE.UPLOAD_ICON:
        return <UploadIcon className={className} />;
      case SVG_TYPE.FILE_ICON:
        return <FileIcon className={className} />;
      case SVG_TYPE.PAW_1:
        return <Paw1 className={className} />;
      case SVG_TYPE.PAW_2:
        return <Paw2 className={className} />;
      case SVG_TYPE.PAW_3:
        return <Paw3 className={className} />;
      case SVG_TYPE.MONEY:
        return <Money className={className} />;
      case SVG_TYPE.DESKTOP_ICON:
        return <DesktopIcon className={className} />;
      case SVG_TYPE.SEARCH_ICON:
        return <SearchIcon className={className} />;
      case SVG_TYPE.SMILE_ICON:
        return <SmileIcon className={className} />;
      case SVG_TYPE.ANNOUNCEMENT_ICON:
        return <AnnouncementIcon className={className} />;
      case SVG_TYPE.MAGNET_LOGO:
        return <MagnetLogo className={className} />;
      case SVG_TYPE.REVIEW_BACKGROUND:
        return <ReviewBackground className={className} />;
      case SVG_TYPE.APOSTROPHE:
        return <Apostrophe className={className} />;
      case SVG_TYPE.CIRCLE_CHECK:
        return <CircleCheck className={className} />;
      case SVG_TYPE.FILE_DOWNLOAD:
        return <FileDownload className={className} />;
      case SVG_TYPE.FACEBOOK:
        return <Facebook className={className} />;
      case SVG_TYPE.INSTAGRAM:
        return <Instagram className={className} />;
      case SVG_TYPE.LINKEDIN:
        return <LinkedIn className={className} />;
      case SVG_TYPE.YOUTUBE:
        return <Youtube className={className} />;
      case SVG_TYPE.PL_DO_YOU_KNOW:
        return <PlDoYouKnow className={className} />;
      case SVG_TYPE.EN_DO_YOU_KNOW:
        return <EnDoYouKnow className={className} />;
      case SVG_TYPE.DE_DO_YOU_KNOW:
        return <DeDoYouKnow className={className} />;
      case SVG_TYPE.CORGI_HEADPHONES:
        return <CorgiHeadphones className={className} />;
      case SVG_TYPE.QUESTION_MARK:
        return <QuestionMark className={className} />;
      case SVG_TYPE.BACKUP_CLOUD:
        return <BackupCloud className={className} />;
      case SVG_TYPE.COPYRIGHT_SECOURITY:
        return <CopyrightSecourity className={className} />;
      case SVG_TYPE.AUTOMATIC_UPDATE:
        return <AutomaticUpdate className={className} />;
      case SVG_TYPE.CONSTANT_MONITORING:
        return <ConstantMonitoring className={className} />;
      case SVG_TYPE.BIG_LOUPE:
        return <BigLoupe className={className} />;
      case SVG_TYPE.BULB:
        return <Bulb className={className} />;
      case SVG_TYPE.HOSTING:
        return <Hosting className={className} />;
      case SVG_TYPE.PHONE_ICON:
        return <PhoneIcon className={className} />;
      case SVG_TYPE.LOCATION_ICON:
        return <LocationIcon className={className} />;
      case SVG_TYPE.MAIL_ICON_3:
        return <MailIcon3 className={className} />;
      case SVG_TYPE.BIN:
        return <BinSVG className={className} />;
      case SVG_TYPE.BACKUP:
        return <BackupSVG className={className} />;
      case SVG_TYPE.CHEVRON_RIGHT:
        return <ChevronRightSVG className={className} />;
      case SVG_TYPE.CHEVRON_LEFT:
        return <ChevronLeftSVG className={className} />;
      case SVG_TYPE.CHEVRON_RIGHT_LIGHT:
        return <ChevronRightLightSVG className={className} />;
      case SVG_TYPE.CHEVRON_DOWN:
        return <ChevronDownSVG className={className} />;
      case SVG_TYPE.GROUP_FILE:
        return <GroupFileSVG className={className} />;
      case SVG_TYPE.CORGI_WITHOUT_WOOF:
        return <CorgiWithoutWoofSVG className={className} />;
      case SVG_TYPE.QUEST:
        return <QuestSVG className={className} />;
      case SVG_TYPE.DOGPAWS:
        return <DogpawsSVG className={className} />;
      case SVG_TYPE.MASTERCARD:
        return <MastercardSVG className={className} />;
      case SVG_TYPE.HAMBURGER:
        return <HamburgerSVG className={className} />;
      case SVG_TYPE.PARTNERS:
        return <PartnersSVG className={className} />;
      case SVG_TYPE.SMS:
        return <SmsSVG className={className} />;
      case SVG_TYPE.WP_WARNING:
        return <WpWarningSVG className={className} />;
      case SVG_TYPE.PASSED:
        return <PassedSVG className={className} />;
      case SVG_TYPE.CHECKED:
        return <CheckedSVG className={className} />;
      case SVG_TYPE.PAWS_2:
        return <Paws2SVG className={className} />;
      case SVG_TYPE.SHIELD_BULE_CHECK:
        return <ShieldBuleCheckSVG className={className} />;
      case SVG_TYPE.CLIENTS:
        return <ClientsSVG className={className} />;
      case SVG_TYPE.CREDIT_CARD2:
        return <CreditCard2SVG className={className} />;
      case SVG_TYPE.BUILDING:
        return <BuildingSVG className={className} />;
      case SVG_TYPE.LOCK_ICON:
        return <LockIconSVG className={className} />;
      case SVG_TYPE.WARNING:
        return <WarningSVG className={className} />;
      case SVG_TYPE.BLUE_CIRCLE_DESKTOP:
        return <BlueCircleDesktopSVG className={className} />;
      case SVG_TYPE.BLUE_CIRCLE_HIGH:
        return <BlueCircleHighSVG className={className} />;
      case SVG_TYPE.PHP_ICON:
        return <PhpIconSVG className={className} />;
      case SVG_TYPE.WORDPRESS:
        return <WordpressSVG className={className} />;
      case SVG_TYPE.PLUGIN:
        return <PluginSVG className={className} />;
      case SVG_TYPE.THEME:
        return <ThemeSVG className={className} />;
      case SVG_TYPE.MAIL_ICON:
        return <MailIconSVG className={className} />;
      case SVG_TYPE.SHUFFLE:
        return <ShuffleSVG className={className} />;
      case SVG_TYPE.WARNING_ICON:
        return <WarningIconSVG className={className} />;
      case SVG_TYPE.FTP_ICON:
        return <FtpIconSVG className={className} />;
      case SVG_TYPE.LOCK_ICON_2:
        return <LockIcon2SVG className={className} />;
      case SVG_TYPE.RED_CIRCLE:
        return <RedCircleSVG className={className} />;
      case SVG_TYPE.CALENDAR:
        return <CalendarSVG className={className} />;
      case SVG_TYPE.FLAG_AU:
        return <FlagAuSVG className={className} />;
      case SVG_TYPE.FLAG_AT:
        return <FlagAtSVG className={className} />;
      case SVG_TYPE.FLAG_BE:
        return <FlagBeSVG className={className} />;
      case SVG_TYPE.FLAG_BG:
        return <FlagBgSVG className={className} />;
      case SVG_TYPE.FLAG_AE:
        return <FlagAeSVG className={className} />;
      case SVG_TYPE.FLAG_CA:
        return <FlagCaSVG className={className} />;
      case SVG_TYPE.FLAG_CH:
        return <FlagChSVG className={className} />;
      case SVG_TYPE.FLAG_CL:
        return <FlagClSVG className={className} />;
      case SVG_TYPE.FLAG_CY:
        return <FlagCySVG className={className} />;
      case SVG_TYPE.FLAG_CZ:
        return <FlagCzSVG className={className} />;
      case SVG_TYPE.FLAG_DE:
        return <FlagDeSVG className={className} />;
      case SVG_TYPE.FLAG_DK:
        return <FlagDkSVG className={className} />;
      case SVG_TYPE.FLAG_EE:
        return <FlagEeSVG className={className} />;
      case SVG_TYPE.FLAG_EL:
        return <FlagElSVG className={className} />;
      case SVG_TYPE.FLAG_ES:
        return <FlagEsSVG className={className} />;
      case SVG_TYPE.FLAG_FI:
        return <FlagFiSVG className={className} />;
      case SVG_TYPE.FLAG_FRAB:
        return <FlagFrabSVG className={className} />;
      case SVG_TYPE.FLAG_HR:
        return <FlagHrSVG className={className} />;
      case SVG_TYPE.FLAG_HU:
        return <FlagHuSVG className={className} />;
      case SVG_TYPE.FLAG_IE:
        return <FlagIeSVG className={className} />;
      case SVG_TYPE.FLAG_IO:
        return <FlagIoSVG className={className} />;
      case SVG_TYPE.FLAG_IRL:
        return <FlagIrlSVG className={className} />;
      case SVG_TYPE.FLAG_IS:
        return <FlagIsSVG className={className} />;
      case SVG_TYPE.FLAG_IT:
        return <FlagItSVG className={className} />;
      case SVG_TYPE.FLAG_JP:
        return <FlagJpSVG className={className} />;
      case SVG_TYPE.FLAG_LV:
        return <FlagLvSVG className={className} />;
      case SVG_TYPE.FLAG_LT:
        return <FlagLtSVG className={className} />;
      case SVG_TYPE.FLAG_LU:
        return <FlagLuSVG className={className} />;
      case SVG_TYPE.FLAG_MT:
        return <FlagMtSVG className={className} />;
      case SVG_TYPE.FLAG_MX:
        return <FlagMxSVG className={className} />;
      case SVG_TYPE.FLAG_NL:
        return <FlagNlSVG className={className} />;
      case SVG_TYPE.FLAG_NZ:
        return <FlagNzSVG className={className} />;
      case SVG_TYPE.FLAG_NO:
        return <FlagNoSVG className={className} />;
      case SVG_TYPE.FLAG_PL:
        return <FlagPlSVG className={className} />;
      case SVG_TYPE.FLAG_PT:
        return <FlagPtSVG className={className} />;
      case SVG_TYPE.FLAG_RO:
        return <FlagRoSVG className={className} />;
      case SVG_TYPE.FLAG_RU:
        return <FlagRuSVG className={className} />;
      case SVG_TYPE.FLAG_SA:
        return <FlagSaSVG className={className} />;
      case SVG_TYPE.FLAG_SK:
        return <FlagSkSVG className={className} />;
      case SVG_TYPE.FLAG_SL:
        return <FlagSlSVG className={className} />;
      case SVG_TYPE.FLAG_PZ:
        return <FlagPzSVG className={className} />;
      case SVG_TYPE.FLAG_KR:
        return <FlagKrSVG className={className} />;
      case SVG_TYPE.FLAG_SE:
        return <FlagSeSVG className={className} />;
      case SVG_TYPE.FLAG_TW:
        return <FlagTwSVG className={className} />;
      case SVG_TYPE.FLAG_TH:
        return <FlagThSVG className={className} />;
      case SVG_TYPE.FLAG_TR:
        return <FlagTrSVG className={className} />;
      case SVG_TYPE.FLAG_UA:
        return <FlagUaSVG className={className} />;
      case SVG_TYPE.FLAG_USA:
        return <FlagUsaSVG className={className} />;
      case SVG_TYPE.FLAG_XI:
        return <FlagXiSVG className={className} />;
      case SVG_TYPE.MAIL_ICON_2:
        return <MailIcon2SVG className={className} />;
      case SVG_TYPE.GLOBE:
        return <GlobeSVG className={className} />;
      case SVG_TYPE.PADLOCK:
        return <PadlockSVG className={className} />;
      case SVG_TYPE.PENCIL:
        return <PencilSVG className={className} />;
      case SVG_TYPE.EXIT:
        return <ExitSVG className={className} />;
      case SVG_TYPE.GIFT:
        return <GiftSVG className={className} />;
      case SVG_TYPE.PERSON:
        return <PersonSVG className={className} />;
      case SVG_TYPE.PHP_VER:
        return <PhpVerSVG className={className} />;
      case SVG_TYPE.SECURITY:
        return <SecuritySVG className={className} />;
      case SVG_TYPE.CORGI_GROUP:
        return <CorgiGroupSVG className={className} />;
      case SVG_TYPE.MONEY_2:
        return <Money2SVG className={className} />;
      case SVG_TYPE.CORGI_SUIT:
        return <CorgiSuitSVG className={className} />;
      case SVG_TYPE.BUBBLE:
        return <BubbleSVG className={className} />;
      case SVG_TYPE.QUESTION_MARKS:
        return <QuestionMarksSVG className={className} />;
      case SVG_TYPE.RED_DOG_LOGO:
        return <RedDogLogoSVG className={className} />;
      case SVG_TYPE.NATALIA_JAGUS_LOGO:
        return <NataliaJagusLogoSVG className={className} />;
      case SVG_TYPE.RED_WOLF_LOGO:
        return <RedWolfLogoSVG className={className} />;
      case SVG_TYPE.WEBSITE_KREACJE_LOGO:
        return <WebsiteKreacjeLogoSVG className={className} />;
      case SVG_TYPE.LINK:
        return <LinkSVG className={className} />;
      case SVG_TYPE.CHECK:
        return <CheckSVG className={className} />;
      case SVG_TYPE.PROFITTO_LOGO:
        return <ProfittoSVG className={className} />;
      case SVG_TYPE.SO_BRIGHT_LOGO:
        return <SoBrightSVG className={className} />;
      case SVG_TYPE.SINGLE_PAGE_BACKUP:
        return <SinglePageBackupSVG className={className} />;
      case SVG_TYPE.SINGLE_PAGE_BACKUPS:
        return <SinglePageBackupsSVG className={className} />;
      case SVG_TYPE.SINGLE_PAGE_MONITORING:
        return <SinglePageMonitoringSVG className={className} />;
      case SVG_TYPE.SINGLE_PAGE_BACKUP_EN:
        return <SinglePageBackupEnSVG className={className} />;
      case SVG_TYPE.SINGLE_PAGE_BACKUPS_EN:
        return <SinglePageBackupsEnSVG className={className} />;
      case SVG_TYPE.SINGLE_PAGE_MONITORING_EN:
        return <SinglePageMonitoringEnSVG className={className} />;
      case SVG_TYPE.CORGI_BIURKO:
        return <CorgiBiurkoSVG className={className} />;
      case SVG_TYPE.DAWEB_STUDIO:
        return <DawebStudioSVG className={className} />;
      default:
        return null;
    }
  };
  return <Suspense fallback="">{getSvg()}</Suspense>;
};

export default SVG;
