import * as Types from 'Shared/models/UserModel';
import { User } from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Shared/enums';

export type State = Types.UserStore;

export const initialState: State = {
  token: null,
  loggedUser: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  subscribersLists: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  clientsLists: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  clients: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  preOrderLists: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  allUsers: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  paymentMethods: {
    data: { data: [], hasSubscription: false },
    status: REQUEST_STATUS.IDLE,
  },
  partnerClients: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
  myWebsites: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  usersList: [],
  company: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
  changelogs: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
  balance: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  activeWebsite: null,
  theme: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  file: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  userWebsites: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  services: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
};

export function userReducer(state: any = initialState, action) {
  switch (action.type) {
    case User.GET_AUTHORIZED_USER_SUCCESS: {
      localStorage.setItem('loggedUserCurrency', action.payload.data.company.currency);
      return {
        ...state,
        loggedUser: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_AUTHORIZED_USER_FAILURE: {
      return {
        ...state,
        loggedUser: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.LOGOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        loggedUser: {
          data: undefined,
          status: REQUEST_STATUS.IDLE,
        },
      };
    }
    case User.GET_PAYMENT_METHODS_INIT: {
      return {
        ...state,
        paymentMethods: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_PAYMENT_METHODS_SUCCESS: {
      const data = action.payload.data;
      const hasSubscription = data['hasActiveSubscription'];
      delete data['hasActiveSubscription'];
      return {
        ...state,
        paymentMethods: {
          data: { data: Object.values(action.payload.data), hasSubscription },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_PAYMENT_METHODS_FAILURE: {
      return {
        ...state,
        paymentMethods: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_MY_WEBSITES_INIT: {
      return {
        ...state,
        myWebsites: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_MY_WEBSITES_SUCCESS: {
      return {
        ...state,
        myWebsites: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_MY_WEBSITES_FAILURE: {
      return {
        ...state,
        myWebsites: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_ALL_USERS_INIT: {
      return {
        ...state,
        allUsers: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        allUsers: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_ALL_USERS_FAILURE: {
      return {
        ...state,
        allUsers: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_PARTNER_CLIENTS_INIT: {
      return {
        ...state,
        partnerClients: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_PARTNER_CLIENTS_SUCCESS: {
      return {
        ...state,
        partnerClients: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_PARTNER_CLIENTS_FAILURE: {
      return {
        ...state,
        partnerClients: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_SUBSCRIBERS_LISTS_INIT: {
      return {
        ...state,
        subscribersLists: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_SUBSCRIBERS_LISTS_SUCCESS: {
      return {
        ...state,
        subscribersLists: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_SUBSCRIBERS_LISTS_FAILURE: {
      return {
        ...state,
        subscribersLists: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_CLIENTS_LISTS_INIT: {
      return {
        ...state,
        clientsLists: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_CLIENTS_LISTS_SUCCESS: {
      return {
        ...state,
        clientsLists: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_CLIENTS_LISTS_FAILURE: {
      return {
        ...state,
        clientsLists: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_CLIENTS_INIT: {
      return {
        ...state,
        clients: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_CLIENTS_FAILURE: {
      return {
        ...state,
        clients: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_PRE_ORDER_LISTS_INIT: {
      return {
        ...state,
        preOrderLists: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_PRE_ORDER_LISTS_SUCCESS: {
      return {
        ...state,
        preOrderLists: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_PRE_ORDER_LISTS_FAILURE: {
      return {
        ...state,
        preOrderLists: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.EDIT_USER_SUCCESS: {
      return {
        ...state,
        loggedUser: {
          data: { ...action.payload.data, company: { ...action.payload.data.company, preOrder: state.loggedUser.data.company.preOrder } },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.DELETE_PAYMENT_METHOD_SUCCESS: {
      const copyData = [...state.paymentMethods.data.data];
      const idx = copyData.findIndex(({ id }) => id === action.payload.id);
      copyData.splice(idx, 1);
      return {
        ...state,
        paymentMethods: {
          data: { ...state.paymentMethods.data, data: copyData },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.SET_ACTIVE_WEBSITE_SUCCESS: {
      return {
        ...state,
        activeWebsite: action.payload.data,
      };
    }
    case User.UPDATE_WEBSITE_SUCCESS: {
      return {
        ...state,
        activeWebsite: { ...state?.activeWebsite, ...action.payload.data },
        myWebsites: {
          ...state.myWebsites,
          data: state?.myWebsites?.data?.map((website) =>
            website?.uuid === action?.payload?.data?.uuid ? { ...website, ...action.payload.data } : website,
          ),
        },
      };
    }
    case User.ADD_WEBSITE_PAYMENT_SUCCESS: {
      return {
        ...state,
        activeWebsite: { ...state.activeWebsite, ...action.payload.data },
      };
    }
    case User.DELETE_WEBSITE_SUCCESS: {
      return {
        ...state,
        myWebsites: { ...state.myWebsites, data: state.myWebsites.data.filter((website) => website.uuid !== action.payload.uuid) },
      };
    }

    case User.ADD_CONNECTIONS_SUCCESS: {
      return {
        ...state,
        activeWebsite: { ...state.activeWebsite, files_connection: { ...action.payload.data } },
      };
    }
    case User.UPDATE_CONNECTION_SUCCESS: {
      return {
        ...state,
        activeWebsite: { ...state.activeWebsite, files_connection: { ...action.payload.data } },
      };
    }
    case User.GET_PARTNER_BALANCE_INIT: {
      return {
        ...state,
        balance: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }

    case User.GET_PARTNER_BALANCE_SUCCESS: {
      return {
        ...state,
        balance: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }

    case User.GET_PARTNER_BALANCE_FAILURE: {
      return {
        ...state,
        balance: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.SAVE_FILE_INIT: {
      return {
        ...state,
        file: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }

    case User.SAVE_FILE_SUCCESS: {
      return {
        ...state,
        file: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }

    case User.SAVE_FILE_FAILURE: {
      return {
        ...state,
        file: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_USER_WEBSITES_SUCCESS: {
      const { websites, companyUuid } = action.payload;
      return {
        ...state,
        userWebsites: {
          data: { ...state.userWebsites.data, [companyUuid]: websites },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.DELETE_USER_INIT: {
      return {
        ...state,
        allUsers: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.DELETE_USER_SUCCESS: {
      const { uuid } = action.payload;
      return {
        ...state,
        allUsers: {
          data: { ...state.allUsers, data: state.allUsers.data.data.filter((user) => user.uuid !== uuid) },
        },
      };
    }

    case User.SEND_INVOICES_SUCCESS: {
      const { value } = action.payload;

      const copyData = {
        ...state.loggedUser.data,
        company: { ...state.loggedUser.data.company, invoice_recipient_email: state.loggedUser.data.company.email },
      };

      !value && {
        ...state,
        loggedUser: {
          copyData,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }

    case User.DELETE_FILE_FAILURE: {
      return {
        ...state,
        allUsers: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_SERVICES_INIT: {
      return {
        ...state,
        services: {
          data: null,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_SERVICES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        services: {
          data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_SERVICES_FAILURE: {
      return {
        ...state,
        services: {
          data: null,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.UPDATE_SERVICES_INIT: {
      return {
        ...state,
        services: {
          data: null,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.UPDATE_SERVICES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        services: {
          data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.UPDATE_SERVICES_FAILURE: {
      return {
        ...state,
        services: {
          data: null,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    case User.UPDATE_THEME_COLOR_SUCCESS: {
      return {
        ...state,
        loggedUser: {
          data: {
            ...state.loggedUser.data,
            theme: action.payload,
          },
        },
      };
    }

    case User.DELETE_FILE_SUCCESS: {
      return {
        ...state,
        loggedUser: {
          data: {
            ...state.loggedUser.data,
            file: null,
          },
        },
      };
    }
    case User.UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
      };
    }
    case User.UPDATE_DEFAULT_PAYMENT_METHOD_FAILURE: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
