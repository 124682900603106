import React, { useEffect, useRef, useState } from 'react';
import { MainType } from './Container';
import { Form, Formik } from 'formik';
import { Step } from 'Screens/AddWebsite/components';
import { FormattedMessage } from 'react-intl';
import { Button, SVG } from 'Shared/components';
import ADD_WEB_SITE_ROUTES from 'Screens/AddWebsite/AddWebsiteRoutes';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { toast } from 'react-toastify';

function CreditCard(props: MainType | any) {
  const formRef = useRef(null);
  const stripe = useStripe();
  const elements = useElements();
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);
  const [showError, setShowError] = useState(false);

  const {
    history,
    translation,
    maxStep,
    formData,
    paymentMethods,
    authorizedUser,
    actions: { addPaymentMethod, updateWebsite, addWebsite },
    intl: { formatMessage },
  } = props;
  const paymentMethodList = paymentMethods?.data;
  const [showButton] = useState(!!paymentMethodList?.length);
  const query = new URLSearchParams(history.location.search);
  const website_uuid = query.get('id');
  const isFtpConfigured = query.get('isFtpConfigured');
  const website = history.location.state?.website;
  const [isPaymentMethodsFormOpen, setIsPaymentMethodsFormOpen] = useState(paymentMethodList?.length);
  const withoutPaymentForm =
    history.location.withoutPaymentForm ||
    (authorizedUser?.company?.subscription_uid && website?.subscription_uid) ||
    (!website && authorizedUser?.company?.subscription_uid);

  useEffect(() => {
    const defaultPaymentMethod = paymentMethodList.find((item) => item.default);
    if (defaultPaymentMethod) {
      setActivePaymentMethod(defaultPaymentMethod.id);
    }
  }, [paymentMethods]);
  useEffect(() => {
    if ((authorizedUser?.company?.subscription_uid && website?.subscription_uid) || withoutPaymentForm) {
      history.push(ADD_WEB_SITE_ROUTES.FTP);
    }
  }, [authorizedUser]);

  const showFailureToast = (id) => {
    toast.update(id, {
      render: (
        <div>
          <h2 className="toast-title toast-title--failure">
            <FormattedMessage id="global.toast_failure" />
          </h2>
          <p>
            <FormattedMessage id="global.toast.add_credit_card_failure" />
          </p>
        </div>
      ),
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG type={SVG_TYPE.CLOSE_CIRCLE_FILL} variant={SVG_TYPE.CLOSE_CIRCLE_FILL} />,
    });
  };

  const handleSubmit = async () => {
    if (isPaymentMethodsFormOpen && activePaymentMethod === null) {
      setShowError(true);
      return;
    }
    const test = await elements.getElement(CardNumberElement);
    if (!formData.website_uuid && !website_uuid) {
      if (!(await addWebsite({ url: formData.url || history.location.url, plan: formData.plan }))) {
        return;
      }
    }

    const id = toast.loading(<FormattedMessage id="global.toast.add_credit_card_loading" />);
    let data: any;
    if (!isPaymentMethodsFormOpen) {
      const { token, error } = await stripe.createToken(test);
      console.log(error);
      if (error !== undefined) {
        showFailureToast(id);
        return;
      } else {
        data = await addPaymentMethod({ stripe_pm_token: { id: token.id } });
        if (!data) {
          showFailureToast(id);
          return;
        }
      }
    } else {
      data = { id: activePaymentMethod };
    }
    if (await updateWebsite({ payment_method_uid: data.id }, formData.website_uuid || website_uuid || sessionStorage.getItem('website_uuid'), true)) {
      toast.update(id, {
        render: (
          <div>
            <h2 className="toast-title toast-title--success">
              <FormattedMessage id="global.toast_succes" />
            </h2>
            <p>
              <FormattedMessage id="global.payments.adding_card" />
            </p>
          </div>
        ),
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
      });
      sessionStorage.removeItem('website_uuid');
      if (!!isFtpConfigured && isFtpConfigured !== 'undefined') {
        history.push(`${ROUTES.MY_WEBSITES}?instalationID=${website_uuid || formData?.website_uuid}`);
        return;
      }
      history.push({ ...history.location, pathname: ADD_WEB_SITE_ROUTES.FTP });
    } else {
      showFailureToast(id);
    }
  };

  function getFirstDayOfNextMonth() {
    const currentDate = new Date();
    const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    return nextMonth;
  }

  function formatDate(date, locale) {
    return date.toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' });
  }

  const getLocale = (locale) => {
    switch (locale) {
      case 'pl':
        return 'pl-PL';
      case 'en':
        return 'en-US';
      case 'de':
        return 'de-DE';
      default:
        return 'pl-PL';
    }
  };
  const showFirstOfNextMonth = () => {
    const nextMonthDate = getFirstDayOfNextMonth();
    const formattedDateUS = formatDate(nextMonthDate, getLocale(translation));
    return formattedDateUS;
  };
  const inputStyles = {
    base: {
      color: '#111',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '400',
      height: '40px',
      fontFamily: 'Poppins, sans-serif',
      border: 'none',
      spacingGridRow: '0',
      spacingGridColumn: '0',
      '::placeholder': {
        color: '#808080',
      },
    },
    invalid: {
      color: '#e20d0d',
      iconColor: '#e20d0d',
    },
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={{ password: '', selectedPacket: 'premium' }}
      //prevent sumit while submitting

      onSubmit={async () => {
        if (sessionStorage.getItem('submitting')) return;

        sessionStorage.setItem('submitting', 'true');
        await handleSubmit();
        sessionStorage.removeItem('submitting');
      }}>
      {() => (
        <Form>
          <Step currentStep={maxStep - 1} lastStep={maxStep} formatMessage={formatMessage} />
          <h2 className="add-website-form__title">{formatMessage({ id: 'global.connect_your_credit_card' })}</h2>
          <h4 className="add-website-form__subtitle">
            {isPaymentMethodsFormOpen ? formatMessage({ id: 'global.credit_card_subtitle2' }) : formatMessage({ id: 'global.credit_card_subtitle' })}
          </h4>
          <div className="add-website-form__tooltip-wrapper">
            <div className={`add-website-form__packet-tooltip add-website-form__packet-tooltip--active`}>
              <SVG type={SVG_TYPE.TWISTED_ARROW} />
              <div className="add-website-form__tooltip-content">
                <SVG className="tooltip-svg" type={SVG_TYPE.SHIELD_BULE_CHECK} />
                <h4>{formatMessage({ id: 'global.you_can_feel_safe' })} </h4>
                <p>{formatMessage({ id: 'global.we_didnt_store_your_data' })}</p>
              </div>
            </div>
          </div>
          <div className="add-website-form__payment-method-button-wrapper">
            <Button
              type="button"
              classNames={`add-website-form__payment-method-button btn__black-stroke btn__black-stroke--transparent 
              ${showButton ? 'add-website-form__payment-method-button--active' : ''} ${
                isPaymentMethodsFormOpen ? 'add-website-form__payment-method-button--opened' : ''
              }`}
              label={isPaymentMethodsFormOpen ? formatMessage({ id: 'global.add_new_card' }) : formatMessage({ id: 'global.your_cards' })}
              onClick={() => setIsPaymentMethodsFormOpen(!isPaymentMethodsFormOpen)}
            />
          </div>
          {isPaymentMethodsFormOpen ? (
            <>
              <label className="form__field--white form__field form__field--text add-website-form__payment-method-label">
                <label className="field-label ">{formatMessage({ id: 'global.your_cards' })}</label>
              </label>
              <ul>
                {paymentMethodList.map((item) => (
                  <li
                    onClick={() => {
                      setActivePaymentMethod(item.id);
                      setShowError(false);
                    }}
                    className={`add-website-form__payment-method ${
                      activePaymentMethod === item.id ? 'add-website-form__payment-method--active' : ''
                    }`}
                    key={item.id}>
                    <div>
                      <SVG className="svg--credit-card" type={SVG_TYPE.CREDIT_CARD} />
                      **** {item.card.last4}
                    </div>
                    <SVG type={SVG_TYPE.ACCEPT_GREEN} />
                  </li>
                ))}
                <div className="add-website-form__payment-method-error-wrapper">
                  <p className={`add-website-form__payment-method-error ${showError ? 'add-website-form__payment-method-error--show' : ''}`}>
                    {formatMessage({ id: 'global.choose_card' })}
                  </p>
                </div>
              </ul>
            </>
          ) : (
            <>
              <label className="form__field--white form__field form__field--text">
                <label className="field-label">{formatMessage({ id: 'global.home_page.packet_card_number' })}</label>
                <CardNumberElement options={{ style: inputStyles, placeholder: '' }} />
              </label>
              <div className="add-website-form__cards">
                <label className="form__field--white form__field form__field--text">
                  <label className="field-label">{formatMessage({ id: 'global.home_page.packet_card_expire' })}</label>
                  <CardExpiryElement options={{ style: inputStyles, placeholder: '' }} />
                </label>
                <label className="form__field--white form__field form__field--text">
                  <label className="field-label">{formatMessage({ id: 'global.home_page.packet_card_code' })}</label>
                  <CardCvcElement options={{ style: inputStyles, placeholder: '' }} />
                </label>
              </div>
            </>
          )}

          <p className="add-website-form__you-can-cancel">{<FormattedMessage id="global.you_can_cancel_subscription" />}</p>
          <p className="add-website-form__first_payment">
            {<FormattedMessage id="global.first_payment_will_be" />}
            <b> {showFirstOfNextMonth()}</b>
          </p>

          <Button type="submit" classNames={'btn__black'} label={formatMessage({ id: 'global.next' })} />
        </Form>
      )}
    </Formik>
  );
}

export default CreditCard;
