import React, { useState, useRef, useEffect } from 'react';
import { MainType } from './Container';
import { Field, Form, Formik } from 'formik';
import { Step } from 'Screens/AddWebsite/components';
import { FormattedMessage } from 'react-intl';
import RegularField from 'Shared/form/regularField/regularField';
import { SVG_TYPE } from 'Shared/enums';
import { Button, SVG } from 'Shared/components';
import ADD_WEB_SITE_ROUTES from 'Screens/AddWebsite/AddWebsiteRoutes';
import * as Yup from 'yup';
import { getFirstDayOfNextMonth, stripePricing } from 'Shared/utils';

function Website(props: MainType) {
  const [websiteStatus, setWebsiteStatus] = useState<any>(false);
  const [isLoaderVisible, setLoaderVisible] = useState(false);
  const [isCheckVisible, setCheckVisible] = useState(false);
  const [checkedUrl, setCheckedUrl] = useState('');
  const {
    history,
    maxStep,
    authorizedUser,
    withoutWebsiteForm,
    translation,
    actions: { checkIsWordpress, addWebsiteFormChange, addWebsite, getMyWebsites },
    intl: { formatMessage },
  } = props;
  const [activeTooltip, setActiveTooltip] = useState(history.location.packages || 'premium');
  const formRef = useRef(null);

  const handleSubmit = async () => {
    addWebsiteFormChange(formRef.current.values);
    if (authorizedUser) {
      const values = formRef.current.values;
      await addWebsite({
        url: values.url,
        plan: values.plan,
        payment_method_uid:
          authorizedUser?.company.subscription_uid && authorizedUser?.company?.payment_method_uid
            ? authorizedUser?.company?.payment_method_uid
            : undefined,
      });
    }
    history.push(ADD_WEB_SITE_ROUTES.ACCOUNT);
  };

  const isMobile = window.innerWidth < 768;
  const handleHover = (packet: string) => {
    !isMobile && setActiveTooltip(packet);
  };

  const handleMouseLeave = () => {
    !isMobile && setActiveTooltip(formRef.current.values.plan);
  };

  useEffect(() => {
    if (withoutWebsiteForm) {
      history.push(ADD_WEB_SITE_ROUTES.ACCOUNT);
    }

    getMyWebsites(true);
  }, []);

  const checkIfWebsiteHasHttp = (url: string) => {
    if (!url.includes('http://') && !url.includes('https://')) {
      formRef.current.setFieldValue('url', `http://${url}`);
      return `http://${url}`;
    }
    return url;
  };

  const customHandleBlur = async ({ currentTarget: { value } }, setFieldTouched) => {
    if (value && value !== checkedUrl) {
      value = checkIfWebsiteHasHttp(value);
      setLoaderVisible(true);
      const result = await checkIsWordpress(value.trim());
      setWebsiteStatus(result);
      setFieldTouched('url', true, true);
      setCheckVisible(true);
      setLoaderVisible(false);
      setCheckedUrl(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      customHandleBlur(event, formRef.current.setFieldTouched);
      event.preventDefault();
    }
  };

  const customOnChange = () => {
    setCheckVisible(false);
    formRef.current.setTouched({ url: false });
    setWebsiteStatus(false);
  };

  const Validation = () => {
    return Yup.object().shape({
      url: Yup.string()
        .required(formatMessage({ id: 'global.validation.required' }))
        .test(
          'url-exist',
          formatMessage({ id: 'global.validation.url_didnt_exist' }),
          () => !(websiteStatus.hasOwnProperty('status') && websiteStatus.status),
        )
        .test(
          'url-isWordpress',
          formatMessage({ id: 'global.validation.wrong_technology' }),
          () => websiteStatus.hasOwnProperty('canBeProtected') && websiteStatus.canBeProtected,
        ),
      // .test('url-is-loading', formatMessage({ id: 'global.validation.page_is_loading' }), () => !isLoaderVisible),
    });
  };

  function formatDate(date, locale) {
    return date.toLocaleDateString(locale, { year: 'numeric', month: 'short', day: 'numeric' });
  }

  const getLocale = (locale) => {
    switch (locale) {
      case 'pl':
        return 'pl-PL';
      case 'en':
        return 'en-US';
      case 'de':
        return 'de-DE';
      default:
        return 'pl-PL';
    }
  };
  const showFirstOfNextMonth = () => {
    const nextMonthDate = getFirstDayOfNextMonth();
    const formattedDateUS = formatDate(nextMonthDate, getLocale(translation));
    return formattedDateUS;
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={Validation()}
      innerRef={formRef}
      initialValues={{ url: '', plan: history.location.packages || 'premium' }}
      onSubmit={async () => {
        handleSubmit();
      }}>
      {({ errors, setFieldTouched, values, setFieldValue, handleSubmit }) => (
        <Form>
          <Step currentStep={1} lastStep={maxStep} formatMessage={formatMessage} />
          <h2 className="add-website-form__title">{formatMessage({ id: 'global.add_websites' })}</h2>
          <h4 className="add-website-form__subtitle">{formatMessage({ id: 'global.protect_website_subtitle' })}</h4>
          <div>
            <Field
              type="text"
              onBlur={(e) => customHandleBlur(e, setFieldTouched)}
              label={<FormattedMessage id="global.packet_form.url" />}
              name="url"
              wrapperClassName={`white ${isCheckVisible ? 'form__field--hide-error' : ''} ${isLoaderVisible ? 'form__field--loader-visible' : ''}`}
              component={RegularField}
              onKeyPress={(e) => handleKeyPress(e)}
              errors={errors}
              errorBelow
              customOnChange={() => customOnChange()}
              svgType={
                isCheckVisible && !isLoaderVisible && !formRef.current.isDirty
                  ? !!websiteStatus.canBeProtected
                    ? SVG_TYPE.ACCEPT_GREEN
                    : SVG_TYPE.CLOSE_CIRCLE_FILL
                  : null
              }
              isLoaderVisible={isLoaderVisible}
            />
            <p
              className={`add-website-form__error ${
                websiteStatus?.status === 'error' || (!websiteStatus?.canBeProtected && !isLoaderVisible) ? 'add-website-form__error--visible' : ''
              }`}>
              {!errors.url && websiteStatus.message === "It looks like the page with the given address doesn't exist or is not working properly"
                ? formatMessage({ id: 'global.validation.url_didnt_exist' })
                : ''}
              {websiteStatus && !errors.url && websiteStatus.canBeProtected == false
                ? formatMessage({ id: 'global.validation.wrong_technology' })
                : ''}
            </p>
          </div>
          <ul className="add-website-form__select-packet" onMouseLeave={() => handleMouseLeave()}>
            <h4>{<FormattedMessage id="global.chose_package" />}</h4>
            <li
              className={`add-website-form__packet ${
                values.plan === 'basic' ? 'add-website-form__packet--active' : values.plan !== '' ? 'add-website-form__packet--disabled' : ''
              }`}
              onClick={() => {
                setActiveTooltip('basic');
                setFieldValue('plan', 'basic');
              }}
              onMouseOver={() => handleHover('basic')}>
              <div className="add-website-form__packet-card">
                <div className="add-website-form__packet-left">
                  <SVG type={SVG_TYPE.PACKETS_BASIC} />
                  Basic
                </div>
                <div className="add-website-form__packet-right">
                  {stripePricing().basic}
                  <span>
                    <p>{stripePricing().currencies}</p>
                    <p>
                      {' '}
                      <FormattedMessage id="global.vat" />
                    </p>
                  </span>
                  <SVG type={SVG_TYPE.ACCEPT_GREEN} />
                </div>
              </div>
              <div className={`add-website-form__packet-tooltip ${activeTooltip === 'basic' ? 'add-website-form__packet-tooltip--active' : ''}`}>
                <SVG type={SVG_TYPE.TWISTED_ARROW} />
                <div className="add-website-form__tooltip-content">
                  <SVG className="tooltip-svg " type={SVG_TYPE.PACKETS_BASIC_NO_BG} />

                  <h4>{formatMessage({ id: 'global.dashboard.packet' })} Basic</h4>
                  <p>
                    <SVG type={SVG_TYPE.CHECK} />
                    {formatMessage({ id: 'global.packet_basic_options.first' })}
                  </p>
                  <p>
                    <SVG type={SVG_TYPE.CHECK} />
                    {formatMessage({ id: 'global.packet_basic_options.second' })}
                  </p>
                </div>
              </div>
            </li>

            <li
              className={`add-website-form__packet ${
                values.plan === 'premium' ? 'add-website-form__packet--active' : values.plan !== '' ? 'add-website-form__packet--disabled' : ''
              }`}
              onClick={() => {
                setFieldValue('plan', 'premium');
                setActiveTooltip('premium');
              }}
              onMouseOver={() => handleHover('premium')}>
              <div className="add-website-form__packet-card">
                <div className="add-website-form__packet-left">
                  <SVG type={SVG_TYPE.PACKETS_PREMIUM} />
                  Premium
                </div>
                <div className="add-website-form__packet-right">
                  {stripePricing().premium}
                  <span>
                    <p>{stripePricing().currencies}</p>
                    <p>
                      {' '}
                      <FormattedMessage id="global.vat" />
                    </p>
                  </span>
                  <SVG type={SVG_TYPE.ACCEPT_GREEN} />
                </div>
              </div>
              <div className={`add-website-form__packet-tooltip ${activeTooltip === 'premium' ? 'add-website-form__packet-tooltip--active' : ''}`}>
                <SVG type={SVG_TYPE.TWISTED_ARROW} />
                <div className="add-website-form__tooltip-content">
                  <SVG className="tooltip-svg tooltip-svg--corgi-head" type={SVG_TYPE.PACKETS_PREMIUM_NO_BG} />

                  <h4>{formatMessage({ id: 'global.dashboard.packet' })} Premium</h4>
                  <p>
                    <SVG type={SVG_TYPE.CHECK} />
                    {formatMessage({ id: 'global.packet_basic_options.first' })}
                  </p>
                  <p>
                    <SVG type={SVG_TYPE.CHECK} />
                    {formatMessage({ id: 'global.packet_premium_options.third' })}
                  </p>
                  <p>
                    <SVG type={SVG_TYPE.CHECK} />
                    {formatMessage({ id: 'global.packet_premium_options.first' })}
                  </p>
                  <p>
                    <SVG type={SVG_TYPE.CHECK} />
                    {formatMessage({ id: 'global.packet_premium_options.second' })}
                  </p>
                </div>
              </div>
            </li>

            <li
              className={`add-website-form__packet ${
                values.plan === 'pro' ? 'add-website-form__packet--active' : values.plan !== '' ? 'add-website-form__packet--disabled' : ''
              }`}
              onClick={() => {
                setFieldValue('plan', 'pro');
                setActiveTooltip('pro');
              }}
              onMouseOver={() => handleHover('pro')}>
              <div className="add-website-form__packet-card">
                <div className="add-website-form__packet-left">
                  <SVG type={SVG_TYPE.PACKETS_PRO} />
                  Pro
                </div>
                <div className="add-website-form__packet-right">
                  {stripePricing().pro}
                  <span>
                    <p>{stripePricing().currencies}</p>
                    <p>
                      {' '}
                      <FormattedMessage id="global.vat" />
                    </p>
                  </span>
                  <SVG type={SVG_TYPE.ACCEPT_GREEN} />
                </div>
              </div>
              <div className={`add-website-form__packet-tooltip ${activeTooltip === 'pro' ? 'add-website-form__packet-tooltip--active' : ''}`}>
                <SVG type={SVG_TYPE.TWISTED_ARROW} />
                <div className="add-website-form__tooltip-content">
                  <SVG className="tooltip-svg" type={SVG_TYPE.PACKETS_PRO_NO_BG} />

                  <h4>{formatMessage({ id: 'global.dashboard.packet' })} Pro</h4>
                  <p>
                    <SVG type={SVG_TYPE.CHECK} />
                    {formatMessage({ id: 'global.packet_basic_options.first' })}
                  </p>
                  <p>
                    <SVG type={SVG_TYPE.CHECK} />
                    {formatMessage({ id: 'global.packet_pro_options.second' })}
                  </p>
                  <p>
                    <SVG type={SVG_TYPE.CHECK} />
                    {formatMessage({ id: 'global.packet_pro_options.third' })}
                  </p>
                  <p>
                    <SVG type={SVG_TYPE.CHECK} />
                    {formatMessage({ id: 'global.packet_premium_options.second' })}
                  </p>
                  <p>
                    <SVG type={SVG_TYPE.CHECK} />
                    {formatMessage({ id: 'global.packet_pro_options.first' })}
                  </p>
                </div>
              </div>
            </li>
          </ul>
          {authorizedUser?.company?.subscription_uid ? (
            <div className="add-website-form__subscription">
              <p>{formatMessage({ id: 'global.it_will_start_subscription' })}</p>
              <br />
              <p>{formatMessage({ id: 'global.first_subscription_payment' })}</p>
              <b>{showFirstOfNextMonth()}</b>
            </div>
          ) : null}
          <Button
            type="button"
            onClick={() => {
              setFieldTouched('url', true, true);
              if (!isLoaderVisible) {
                handleSubmit();
              }
            }}
            classNames={'btn__black'}
            label={formatMessage({ id: 'global.next' })}
          />
        </Form>
      )}
    </Formik>
  );
}

export default Website;
