export enum Auth {
  GET_LOCATION_INIT = 'GET_LOCATION_INIT',
  GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS',
  GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE',
  LOG_IN_INIT = 'LOG_IN_INIT',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE = 'LOG_IN_FAILURE',
  SET_COMPANY_VAT_INIT = 'SET_COMPANY_VAT_INIT',
  SET_COMPANY_VAT_SUCCESS = 'SET_COMPANY_VAT_SUCCESS',
  SET_COMPANY_VAT_FAILURE = 'SET_COMPANY_VAT_FAILURE',
  LOG_OUT_INIT = 'LOG_OUT_INIT',
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
  LOG_OUT_FAILURE = 'LOG_OUT_FAILURE',
  GET_AUTHORIZED_USER_INIT = 'GET_AUTHORIZED_USER_INIT',
  GET_AUTHORIZED_USER_SUCCESS = 'GET_AUTHORIZED_USER_SUCCESS',
  GET_AUTHORIZED_USER_FAILURE = 'GET_AUTHORIZED_USER_FAILURE',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  SEND_LINK_INIT = 'SEND_LINK_INIT',
  SEND_LINK_SUCCESS = 'SEND_LINK_SUCCESS',
  SEND_LINK_FAILURE = 'SEND_LINK_FAILURE',
  GET_REFRESH_TOKEN_INIT = 'GET_REFRESH_TOKEN_INIT',
  GET_REFRESH_TOKEN_SUCCESS = 'GET_REFRESH_TOKEN_SUCCESS',
  GET_REFRESH_TOKEN_FAILURE = 'GET_REFRESH_TOKEN_FAILURE',
  ADD_NEW_DEVICE_INIT = 'ADD_NEW_DEVICE_INIT',
  ADD_NEW_DEVICE_SUCCESS = 'ADD_NEW_DEVICE_SUCCESS',
  ADD_NEW_DEVICE_FAILURE = 'ADD_NEW_DEVICE_FAILURE',
  RECOVER_PASSWORD_INIT = 'RECOVER_PASSWORD_INIT',
  RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS',
  RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE',
  SET_NEW_PASSWORD_INIT = 'SET_NEW_PASSWORD_INIT',
  SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE',
  CHECK_RECOVERY_TOKEN_INIT = 'CHECK_RECOVERY_TOKEN_INIT',
  CHECK_RECOVERY_TOKEN_SUCCESS = 'CHECK_RECOVERY_TOKEN_SUCCESS',
  CHECK_RECOVERY_TOKEN_FAILURE = 'CHECK_RECOVERY_TOKEN_FAILURE',
}

export enum ViewManagement {
  SHOW_LOADER = 'SHOW_LOADER',
  HIDE_LOADER = 'HIDE_LOADER',
  SHOW_DASHBOARD_USER_PAYMENTS_LOADER = 'SHOW_DASHBOARD_USER_PAYMENTS_LOADER',
  HIDE_DASHBOARD_USER_PAYMENTS_LOADER = 'HIDE_DASHBOARD_USER_PAYMENTS_LOADER',
  SHOW_DASHBOARD_WEBSITES_INFO_LOADER = 'SHOW_DASHBOARD_WEBSITES_INFO_LOADER',
  HIDE_DASHBOARD_WEBSITES_INFO_LOADER = 'HIDE_DASHBOARD_WEBSITES_INFO_LOADER',
  SHOW_DASHBOARD_WEBSITES_ACTIONS_LOADER = 'SHOW_DASHBOARD_WEBSITES_ACTIONS_LOADER',
  HIDE_DASHBOARD_WEBSITES_ACTIONS_LOADER = 'HIDE_DASHBOARD_WEBSITES_ACTIONS_LOADER',
  SET_PREV_PATH = 'SET_PREV_PATH',
  EXPAND_MENU = 'EXPAND_MENU',
  SET_MENU_EXPANDED = '@@ViewManagement/SET_MENU_EXPANDED',
  EXPAND_ADD_REMINDER_VIEW = 'EXPAND_ADD_REMINDER_VIEW',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  SET_APP_TRANSLATION_SUCCESS = 'SET_APP_TRANSLATION_SUCCESS',
  SET_APP_TRANSLATION_FAILURE = 'SET_APP_TRANSLATION_FAILURE',
}

export enum User {
  DELETE_PAYMENT_METHOD_INIT = 'ADD_PAYMENT_METHODS_INIT',
  DELETE_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHODS_SUCCESS',
  DELETE_PAYMENT_METHOD_FAILURE = 'ADD_PAYMENT_METHODS_FAILURE',
  ///
  EDIT_SETTINGS_INIT = 'EDIT_SETTINGS_INIT',
  EDIT_SETTINGS_SUCCESS = 'EDIT_SETTINGS_SUCCESS',
  EDIT_SETTINGS_FAILURE = 'EDIT_SETTINGS_FAILURE',
  ///
  ADD_PAYMENT_METHOD_INIT = 'ADD_PAYMENT_METHODS_INIT',
  ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHODS_SUCCESS',
  ADD_PAYMENT_METHOD_FAILURE = 'ADD_PAYMENT_METHODS_FAILURE',
  ///
  GET_PAYMENT_METHODS_INIT = 'GET_PAYMENT_METHODS_INIT',
  GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS',
  GET_PAYMENT_METHODS_FAILURE = 'GET_PAYMENT_METHODS_FAILURE',
  ///
  GET_MY_WEBSITES_INIT = 'GET_MY_WEBSITES_INIT',
  GET_MY_WEBSITES_SUCCESS = 'GET_MY_WEBSITES_SUCCESS',
  GET_MY_WEBSITES_FAILURE = 'GET_MY_WEBSITES_FAILURE',
  ///
  GET_AUTHORIZED_USER_INIT = 'GET_AUTHORIZED_USER_INIT',
  GET_AUTHORIZED_USER_SUCCESS = 'GET_AUTHORIZED_USER_SUCCESS',
  GET_AUTHORIZED_USER_FAILURE = 'GET_AUTHORIZED_USER_FAILURE',
  ///
  GET_CLIENTS_LISTS_INIT = 'GET_CLIENTS_LISTS_INIT',
  GET_CLIENTS_LISTS_SUCCESS = 'GET_CLIENTS_LISTS_SUCCESS',
  GET_CLIENTS_LISTS_FAILURE = 'GET_CLIENTS_LISTS_FAILURE',
  ///
  GET_CLIENTS_INIT = 'GET_CLIENTS_INIT',
  GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS',
  GET_CLIENTS_FAILURE = 'GET_CLIENTS_FAILURE',
  ///
  GET_USERS_INIT = 'GET_USERS_INIT',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'GET_USERS_FAILURE',
  ///
  GET_ALL_USERS_INIT = 'GET_ALL_USERS_INIT',
  GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE',
  ///
  SEND_CONTACT_FORM_INIT = 'SEND_CONTACT_FORM_INIT',
  SEND_CONTACT_FORM_SUCCESS = 'SEND_CONTACT_FORM_SUCCESS',
  SEND_CONTACT_FORM_FAILURE = 'SEND_CONTACT_FORM_FAILURE',
  ///
  SEND_PREORDER_FORM_INIT = 'SEND_PREORDER_FORM_INIT',
  SEND_PREORDER_FORM_SUCCESS = 'SEND_PREORDER_FORM_SUCCESS',
  SEND_PREORDER_FORM_FAILURE = 'SEND_PREORDER_FORM_FAILURE',
  ///
  REGISTER_PARTNER_FORM_INIT = 'REGISTER_PARTNER_FORM_INIT',
  REGISTER_PARTNER_FORM_SUCCESS = 'REGISTER_PARTNER_FORM_SUCCESS',
  REGISTER_PARTNER_FORM_FAILURE = 'REGISTER_PARTNER_FORM_FAILURE',
  ///
  CHECKER_REGISTER_INIT = 'CHECKER_REGISTER_INIT',
  CHECKER_REGISTER_SUCCESS = 'CHECKER_REGISTER_SUCCESS',
  CHECKER_REGISTER_FAILURE = 'CHECKER_REGISTER_FAILURE',
  ///
  SEND_NEWSLETTER_FORM_INIT = 'SEND_NEWSLETTER_FORM_INIT',
  SEND_NEWSLETTER_FORM_SUCCESS = 'SEND_NEWSLETTER_FORM_SUCCESS',
  SEND_NEWSLETTER_FORM_FAILURE = 'SEND_NEWSLETTER_FORM_FAILURE',
  ///
  EDIT_USER_INIT = 'EDIT_USER_INIT',
  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE = 'EDIT_USER_FAILURE',
  ///
  SMS_METHOD_INIT = 'SMS_METHOD_INIT',
  SMS_METHOD_SUCCESS = 'SMS_METHOD_SUCCESS',
  SMS_METHOD_FAILURE = 'SMS_METHOD_FAILURE',
  ///
  CHANGE_PASSWORD_INIT = 'CHANGE_PASSWORD_INIT',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE',
  ///
  GET_SUBSCRIBERS_LISTS_INIT = 'GET_SUBSCRIBERS_LISTS_INIT',
  GET_SUBSCRIBERS_LISTS_SUCCESS = 'GET_SUBSCRIBERS_LISTS_SUCCESS',
  GET_SUBSCRIBERS_LISTS_FAILURE = 'GET_SUBSCRIBERS_LISTS_FAILURE',
  ///
  GET_PRE_ORDER_LISTS_INIT = 'GET_PRE_ORDER_LISTS_INIT',
  GET_PRE_ORDER_LISTS_SUCCESS = 'GET_PRE_ORDER_LISTS_SUCCESS',
  GET_PRE_ORDER_LISTS_FAILURE = 'GET_PRE_ORDER_LISTS_FAILURE',
  //
  GET_PARTNER_CLIENTS_INIT = 'GET_PARTNER_CLIENTS_INIT',
  GET_PARTNER_CLIENTS_SUCCESS = 'GET_PARTNER_CLIENTS_SUCCESS',
  GET_PARTNER_CLIENTS_FAILURE = 'GET_PARTNER_CLIENTS_FAILURE',
  //
  CHECK_IS_WORDPRESS_INIT = 'CHECK_IS_WORDPRESS_INIT',
  CHECK_IS_WORDPRESS_SUCCESS = 'CHECK_IS_WORDPRESS_SUCCESS',
  CHECK_IS_WORDPRESS_FAILURE = 'CHECK_IS_WORDPRESS_FAILURE',
  //
  ADD_WEBSITES_INIT = 'ADD_WEBSITES_INIT',
  ADD_WEBSITES_SUCCESS = 'ADD_WEBSITES_SUCCESS',
  ADD_WEBSITES_FAILURE = 'ADD_WEBSITES_FAILURE',
  //
  ADD_CONNECTIONS_INIT = 'ADD_CONNECTIONS_INIT',
  ADD_CONNECTIONS_SUCCESS = 'ADD_CONNECTIONS_SUCCESS',
  ADD_CONNECTIONS_FAILURE = 'ADD_CONNECTIONS_FAILURE',
  //
  ADD_WEBSITE_PAYMENT_INIT = 'ADD_WEBSITE_PAYMENT_INIT',
  ADD_WEBSITE_PAYMENT_SUCCESS = 'ADD_WEBSITE_PAYMENT_SUCCESS',
  ADD_WEBSITE_PAYMENT_FAILURE = 'ADD_WEBSITE_PAYMENT_FAILURE',
  //
  REGULATIONS_PARTNER_INIT = 'REGULATIONS_PARTNER_INIT',
  REGULATIONS_PARTNER_SUCCESS = 'REGULATIONS_PARTNER_SUCCESS',
  REGULATIONS_PARTNER_FAILURE = 'REGULATIONS_PARTNER_FAILURE',
  //
  UPDATE_WEBSITE_INIT = 'UPDATE_WEBSITE_INIT',
  UPDATE_WEBSITE_SUCCESS = 'UPDATE_WEBSITE_SUCCESS',
  UPDATE_WEBSITE_FAILURE = 'UPDATE_WEBSITE_FAILURE',
  //
  SET_ACTIVE_WEBSITE_INIT = 'SET_ACTIVE_WEBSITE_INIT',
  SET_ACTIVE_WEBSITE_SUCCESS = 'SET_ACTIVE_WEBSITE_SUCCESS',
  SET_ACTIVE_WEBSITE_FAILURE = 'SET_ACTIVE_WEBSITE_FAILURE',
  //
  UPDATE_CONNECTION_INIT = 'UPDATE_CONNECTION_INIT',
  UPDATE_CONNECTION_SUCCESS = 'UPDATE_CONNECTION_SUCCESS',
  UPDATE_CONNECTION_FAILURE = 'UPDATE_CONNECTION_FAILURE',

  DELETE_WEBSITE_INIT = 'DELETE_WEBSITE_INIT',
  DELETE_WEBSITE_SUCCESS = 'DELETE_WEBSITE_SUCCESS',
  DELETE_WEBSITE_FAILURE = 'DELETE_WEBSITE_FAILURE',

  CANCEL_SUBSCRIPTION_INIT = 'CANCEL_SUBSCRIPTION_INIT',
  CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE',

  SEND_INVOICES_INIT = 'SEND_INVOICES_INIT',
  SEND_INVOICES_SUCCESS = 'SEND_INVOICES_SUCCESS',
  SEND_INVOICES_FAILURE = 'SEND_INVOICES_FAILURE',

  //Partner balance
  GET_PARTNER_BALANCE_INIT = 'GET_PARTNER_BALANCE_INIT',
  GET_PARTNER_BALANCE_SUCCESS = 'GET_PARTNER_BALANCE_SUCCESS',
  GET_PARTNER_BALANCE_FAILURE = 'GET_PARTNER_BALANCE_FAILURE',

  //Save file
  SAVE_FILE_INIT = 'SAVE_FILE_INIT',
  SAVE_FILE_SUCCESS = 'SAVE_FILE_SUCCESS',
  SAVE_FILE_FAILURE = 'SAVE_FILE_FAILURE',

  //Delete file
  DELETE_FILE_INIT = 'DELETE_FILE_INIT',
  DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS',
  DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE',

  //Update theme color
  UPDATE_THEME_COLOR_INIT = 'UPDATE_THEME_COLOR_INIT',
  UPDATE_THEME_COLOR_SUCCESS = 'UPDATE_THEME_COLOR_SUCCESS',
  UPDATE_THEME_COLOR_FAILURE = 'UPDATE_THEME_COLOR_FAILURE',

  //
  GET_USER_WEBSITES_SUCCESS = 'GET_USER_WEBSITES_SUCCESS',

  //
  DELETE_USER_INIT = 'DELETE_USER_INIT',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE = 'DELETE_USER_FAILURE',

  //
  UPDATE_SERVICES_INIT = 'UPDATE_SERVICES_INIT',
  UPDATE_SERVICES_SUCCESS = 'UPDATE_SERVICES_SUCCESS',
  UPDATE_SERVICES_FAILURE = 'UPDATE_SERVICES_FAILURE',

  //
  GET_SERVICES_INIT = 'GET_SERVICES_INIT',
  GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS',
  GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE',

  //
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',

  //
  UPDATE_DEFAULT_PAYMENT_METHOD_INIT = 'UPDATE_DEFAULT_PAYMENT_METHOD_INIT',
  UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS = 'UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS',
  UPDATE_DEFAULT_PAYMENT_METHOD_FAILURE = 'UPDATE_DEFAULT_PAYMENT_METHOD_FAILURE',
}

export enum Website {
  GET_DASHBOARD_USER_WEBSITES_INIT = 'GET_DASHBOARD_USER_WEBSITES_INIT',
  GET_DASHBOARD_USER_WEBSITES_SUCCESS = 'GET_DASHBOARD_USER_WEBSITES_SUCCESS',
  GET_DASHBOARD_USER_WEBSITES_FAILURE = 'GET_DASHBOARD_USER_WEBSITES_FAILURE',
  ///
  GET_DASHBOARD_CLIENTS_WEBSITES_INIT = 'GET_DASHBOARD_CLIENTS_WEBSITES_INIT',
  GET_DASHBOARD_CLIENTS_WEBSITES_SUCCESS = 'GET_DASHBOARD_CLIENTS_WEBSITES_SUCCESS',
  GET_DASHBOARD_CLIENTS_WEBSITES_FAILURE = 'GET_DASHBOARD_CLIENTS_WEBSITES_FAILURE',
  ///
  GET_DASHBOARD_WEBSITES_AMOUNTS_INIT = 'GET_DASHBOARD_WEBSITES_AMOUNTS_INIT',
  GET_DASHBOARD_WEBSITES_AMOUNTS_SUCCESS = 'GET_DASHBOARD_WEBSITES_AMOUNTS_SUCCESS',
  GET_DASHBOARD_WEBSITES_AMOUNTS_FAILURE = 'GET_DASHBOARD_WEBSITES_AMOUNTS_FAILURE',
  ///
  GET_DASHBOARD_PARTNER_WEBSITES_INIT = 'GET_DASHBOARD_PARTNER_WEBSITES_INIT',
  GET_DASHBOARD_PARTNER_WEBSITES_SUCCESS = 'GET_DASHBOARD_PARTNER_WEBSITES_SUCCESS',
  GET_DASHBOARD_PARTNER_WEBSITES_FAILURE = 'GET_DASHBOARD_PARTNER_WEBSITES_FAILURE',
  ///
  GET_DASHBOARD_CLIENTS_WEBSITES_AMOUNTS_INIT = 'GET_DASHBOARD_CLIENTS_WEBSITES_AMOUNTS_INIT',
  GET_DASHBOARD_CLIENTS_WEBSITES_AMOUNTS_SUCCESS = 'GET_DASHBOARD_CLIENTS_WEBSITES_AMOUNTS_SUCCESS',
  GET_DASHBOARD_CLIENTS_WEBSITES_AMOUNTS_FAILURE = 'GET_DASHBOARD_CLIENTS_WEBSITES_AMOUNTS_FAILURE',
  ///
  GET_DASHBOARD_WEBSITES_ACTIONS_INIT = 'GET_DASHBOARD_WEBSITES_ACTIONS_INIT',
  GET_DASHBOARD_WEBSITES_ACTIONS_SUCCESS = 'GET_DASHBOARD_WEBSITES_ACTIONS_SUCCESS',
  GET_DASHBOARD_WEBSITES_ACTIONS_FAILURE = 'GET_DASHBOARD_WEBSITES_ACTIONS_FAILURE',
  ///
  GET_DASHBOARD_CLIENTS_WEBSITES_ACTIONS_INIT = 'GET_DASHBOARD_CLIENTS_WEBSITES_ACTIONS_INIT',
  GET_DASHBOARD_CLIENTS_WEBSITES_ACTIONS_SUCCESS = 'GET_DASHBOARD_CLIENTS_WEBSITES_ACTIONS_SUCCESS',
  GET_DASHBOARD_CLIENTS_WEBSITES_ACTIONS_FAILURE = 'GET_DASHBOARD_CLIENTS_WEBSITES_ACTIONS_FAILURE',
  ///
  GET_DASHBOARD_PARTNERS_INFO_INIT = 'GET_DASHBOARD_PARTNERS_INFO_INIT',
  GET_DASHBOARD_PARTNERS_INFO_SUCCESS = 'GET_DASHBOARD_PARTNERS_INFO_SUCCESS',
  GET_DASHBOARD_PARTNERS_INFO_FAILURE = 'GET_DASHBOARD_PARTNERS_INFO_FAILURE',
  ///
  GET_ABOUT_SINGLE_WEBSITE_INIT = 'GET_ABOUT_SINGLE_WEBSITE_INIT',
  GET_ABOUT_SINGLE_WEBSITE_SUCCESS = 'GET_ABOUT_SINGLE_WEBSITE_SUCCESS',
  GET_ABOUT_SINGLE_WEBSITE_FAILURE = 'GET_ABOUT_SINGLE_WEBSITE_FAILURE',
  ///
  GET_SINGLE_WEBSITE_SETTINGS_INIT = 'GET_SINGLE_WEBSITE_SETTINGS_INIT',
  GET_SINGLE_WEBSITE_SETTINGS_SUCCESS = 'GET_SINGLE_WEBSITE_SETTINGS_SUCCESS',
  GET_SINGLE_WEBSITE_SETTINGS_FAILURE = 'GET_SINGLE_WEBSITE_SETTINGS_FAILURE',
  ///
  GET_SINGLE_WEBSITE_STATISTICS_INIT = 'GET_SINGLE_WEBSITE_STATISTICS_INIT',
  GET_SINGLE_WEBSITE_STATISTICS_SUCCESS = 'GET_SINGLE_WEBSITE_STATISTICS_SUCCESS',
  GET_SINGLE_WEBSITE_STATISTICS_FAILURE = 'GET_SINGLE_WEBSITE_STATISTICS_FAILURE',
  ///
  GET_SINGLE_WEBSITE_ACTIONS_INIT = 'GET_SINGLE_WEBSITE_ACTIONS_INIT',
  GET_SINGLE_WEBSITE_ACTIONS_SUCCESS = 'GET_SINGLE_WEBSITE_ACTIONS_SUCCESS',
  GET_SINGLE_WEBSITE_ACTIONS_FAILURE = 'GET_SINGLE_WEBSITE_ACTIONS_FAILURE',
  ///
  GET_PARTNERS_WEBSITES_INIT = 'GET_PARTNERS_WEBSITES_INIT',
  GET_PARTNERS_WEBSITES_SUCCESS = 'GET_PARTNERS_WEBSITES_SUCCESS',
  GET_PARTNERS_WEBSITES_FAILURE = 'GET_PARTNERS_WEBSITES_FAILURE',
  ///
  CLEAR_SINGLE_WEBSITE_STATE_SUCCESS = 'CLEAR_SINGLE_WEBSITE_STATE_SUCCESS',
  ///
  GET_WEBSITES_ALL_INIT = 'GET_WEBSITES_ALL_INIT',
  GET_WEBSITES_ALL_SUCCESS = 'GET_WEBSITES_ALL_SUCCESS',
  GET_WEBSITES_ALL_FAILURE = 'GET_WEBSITES_ALL_FAILURE',
  ///
  GET_WEBSITES_ALL_COUNTS_INIT = 'GET_WEBSITES_ALL_COUNTS_INIT',
  GET_WEBSITES_ALL_COUNTS_SUCCESS = 'GET_WEBSITES_ALL_COUNTS_SUCCESS',
  GET_WEBSITES_ALL_COUNTS_FAILURE = 'GET_WEBSITES_ALL_COUNTS_FAILURE',
  ///
  GET_CUSTOM_PINGS_INIT = 'GET_CUSTOM_PINGS_INIT',
  GET_CUSTOM_PINGS_SUCCESS = 'GET_CUSTOM_PINGS_SUCCESS',
  GET_CUSTOM_PINGS_FAILURE = 'GET_CUSTOM_PINGS_FAILURE',
  ///
  GET_ERROR_DETAILS_INIT = 'GET_ERROR_DETAILS_INIT',
  GET_ERROR_DETAILS_SUCCESS = 'GET_ERROR_DETAILS_SUCCESS',
  GET_ERROR_DETAILS_FAILURE = 'GET_ERROR_DETAILS_FAILURE',
  ///
  CLEAR_CUSTOM_PINGS_SUCCESS = 'CLEAR_CUSTOM_PINGS_SUCCESS',
  ///
  GET_WEBINAR_DETAILS_INIT = 'GET_WEBINAR_DETAILS_INIT',
  GET_WEBINAR_DETAILS_SUCCESS = 'GET_WEBINAR_DETAILS_SUCCESS',
  GET_WEBINAR_DETAILS_FAILURE = 'GET_WEBINAR_DETAILS_FAILURE',
  ///
  GET_REPORT_DATA_INIT = 'GET_REPORT_DATA_INIT',
  GET_REPORT_DATA_SUCCESS = 'GET_REPORT_DATA_SUCCESS',
  GET_REPORT_DATA_FAILURE = 'GET_REPORT_DATA_FAILURE',
  ///
  GET_DIAGNOSTIC_DATA_INIT = 'GET_DIAGNOSTIC_DATA_INIT',
  GET_DIAGNOSTIC_DATA_SUCCESS = 'GET_DIAGNOSTIC_DATA_SUCCESS',
  GET_DIAGNOSTIC_DATA_FAILURE = 'GET_DIAGNOSTIC_DATA_FAILURE',
  //
  RUN_DIAGNOSTIC_INIT = 'RUN_DIAGNOSTIC_INIT',
  RUN_DIAGNOSTIC_SUCCESS = 'RUN_DIAGNOSTIC_SUCCESS',
  RUN_DIAGNOSTIC_FAILURE = 'RUN_DIAGNOSTIC_FAILURE',
  //
  CHECK_WEBSITE_INIT = 'CHECK_WEBSITE_INIT',
  CHECK_WEBSITE_SUCCESS = 'CHECK_WEBSITE_SUCCESS',
  CHECK_WEBSITE_FAILURE = 'CHECK_WEBSITE_FAILURE',
  ///
  CHECKER_LIST_SUCCESS = 'CHECKER_LIST_SUCCESS',
  CHECKER_LIST_FAILURE = 'CHECKER_LIST_FAILURE',
  ///
  UPDATE_CHECKER_SUCCESS = 'UPDATE_CHECKER_SUCCESS',

  GET_DASHBOARD_PARTNER_AMOUNTS_SUCCESS = 'GET_DASHBOARD_PARTNER_AMOUNTS_SUCCESS',
  GET_DASHBOARD_PARTNER_AMOUNTS_FAILURE = 'GET_DASHBOARD_PARTNER_AMOUNTS_FAILURE',
  ///
  GET_DASHBOARD_PARTNER_ACTIONS_SUCCESS = 'GET_DASHBOARD_PARTNER_ACTIONS_SUCCESS',
  GET_DASHBOARD_PARTNER_ACTIONS_FAILURE = 'GET_DASHBOARD_PARTNER_ACTIONS_FAILURE',
  ///
  GET_WP_USERS_INIT = 'GET_WP_USERS_INIT',
  GET_WP_USERS_SUCCESS = 'GET_WP_USERS_SUCCESS',
  GET_WP_USERS_FAILURE = 'GET_WP_USERS_FAILURE',
  ///
  EDIT_WP_USERS_INIT = 'EDIT_WP_USERS_INIT',
  EDIT_WP_USERS_SUCCESS = 'EDIT_WP_USERS_SUCCESS',
  EDIT_WP_USERS_FAILURE = 'EDIT_WP_USERS_FAILURE',
  ///
  UPDATE_WEBSITE_CONTACT_NUMBER_INIT = 'UPDATE_WEBSITE_CONTACT_NUMBER_INIT',
  UPDATE_WEBSITE_CONTACT_NUMBER_SUCCESS = 'UPDATE_WEBSITE_CONTACT_NUMBER_SUCCESS',
  UPDATE_WEBSITE_CONTACT_NUMBER_FAILURE = 'UPDATE_WEBSITE_CONTACT_NUMBEFAILURE',
  ///
  MARK_WP_USER_ACTIVE_INIT = 'MARK_WP_USER_ACTIVE_INIT',
  MARK_WP_USER_ACTIVE_SUCCESS = 'MARK_WP_USER_ACTIVE_SUCCESS',
  MARK_WP_USER_ACTIVE_FAILURE = 'MARK_WP_USER_ACTIVE_FAILURE',
  ///
  GET_WORDPRESS_AVAILABLE_UPDATES_INIT = 'GET_WORDPRESS_AVAILABLE_UPDATES_INIT',
  GET_WORDPRESS_AVAILABLE_UPDATES_SUCCESS = 'GET_WORDPRESS_AVAILABLE_UPDATES_SUCCESS',
  GET_WORDPRESS_AVAILABLE_UPDATES_FAILURE = 'GET_WORDPRESS_AVAILABLE_UPDATES_FAILURE',
  ///
  GET_ADDITIONAL_URLS_INIT = 'GET_ADDITIONAL_URLS_INIT',
  GET_ADDITIONAL_URLS_SUCCESS = 'GET_ADDITIONAL_URLS_SUCCESS',
  GET_ADDITIONAL_URLS_FAILURE = 'GET_ADDITIONAL_URLS_FAILURE',
  ///
  GET_RESOURCES_LIST_INIT = 'GET_RESOURCES_LIST_INIT',
  GET_RESOURCES_LIST_SUCCESS = 'GET_RESOURCES_LIST_SUCCESS',
  GET_RESOURCES_LIST_FAILURE = 'GET_RESOURCES_LIST_FAILURE',
  ///
  UPDATE_RESOURCES_INIT = 'UPDATE_RESOURCES_INIT',
  UPDATE_RESOURCES_SUCCESS = 'UPDATE_RESOURCES_SUCCESS',
  UPDATE_RESOURCES_FAILURE = 'UPDATE_RESOURCES_FAILURE',
}

export enum Notification {
  GET_NOTIFICATION_INIT = 'GET_NOTIFICATION_INIT',
  GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS',
  GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE',
  ///
  GET_NOTIFICATION_WEBSITE_INIT = 'GET_NOTIFICATION_WEBSITE_INIT',
  GET_NOTIFICATION_WEBSITE_SUCCESS = 'GET_NOTIFICATION_WEBSITE_SUCCESS',
  GET_NOTIFICATION_WEBSITE_FAILURE = 'GET_NOTIFICATION_WEBSITE_FAILURE',
  ///
  GET_NOTIFICATION_PARTNERS_INIT = 'GET_NOTIFICATION_PARTNERS_INIT',
  GET_NOTIFICATION_PARTNERS_SUCCESS = 'GET_NOTIFICATION_PARTNERS_SUCCESS',
  GET_NOTIFICATION_PARTNERS_FAILURE = 'GET_NOTIFICATION_PARTNERS_FAILURE',
}

export enum AddWebsite {
  USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS',
  USER_REGISTRATION_FAILURE = 'USER_REGISTRATION_FAILURE',
  ///
  ADD_WEBISTE_FORM_CHANGE = 'ADD_WEBISTE_FORM_CHANGE',
  ADD_WEBSITE_FORM_RESET = 'ADD_WEBSITE_FORM_RESET',
  ///
  FILL_COMPANY_DATA_SUCCESS = 'FILL_COMPANY_DATA_SUCCESS',
  FILL_COMPANY_DATA_FAILURE = 'FILL_COMPANY_DATA_FAILURE',
  ///
  ADD_WEBSITE_SUCCESS = 'ADD_WEBSITE_SUCCESS',
  ADD_WEBSITE_FAILURE = 'ADD_WEBSITE_FAILURE',
  ///
  ADD_FTP_SUCCESS = 'ADD_FTP_SUCCESS',
  ADD_FTP_FAILURE = 'ADD_FTP_FAILURE',

  ///
  CHANGE_MAX_STEP = 'CHANGE_MAX_STEP',
  CHANGE_CURRENT_STEP = 'CHANGE_CURRENT_STEP',
  //
}

export enum Backup {
  GET_BACKUPS_INIT = 'GET_BACKUPS_INIT',
  GET_BACKUPS_SUCCESS = 'GET_BACKUPS_SUCCESS',
  GET_BACKUPS_FAILURE = 'GET_BACKUPS_FAILURE',
  ////
  GET_BACKUPS_PARTNERS_INIT = 'GET_BACKUPS_PARTNERS_INIT',
  GET_BACKUPS_PARTNERS_SUCCESS = 'GET_BACKUPS_PARTNERS_SUCCESS',
  GET_BACKUPS_PARTNERS_FAILURE = 'GET_BACKUPS_PARTNERS_FAILURE',
  ////
  GET_BACKUPS_WEBSITE_INIT = 'GET_BACKUPS_WEBSITE_INIT',
  GET_BACKUPS_WEBSITE_SUCCESS = 'GET_BACKUPS_WEBSITE_SUCCESS',
  GET_BACKUPS_WEBSITE_FAILURE = 'GET_BACKUPS_WEBSITE_FAILURE',
  ////
  GET_RESTORE_BACKUP_INIT = 'GET_RESTORE_BACKUP_INIT',
  GET_RESTORE_BACKUP_SUCCESS = 'GET_RESTORE_BACKUP_SUCCESS',
  GET_RESTORE_BACKUP_FAILURE = 'GET_RESTORE_BACKUP_FAILURE',
  ////
  GET_DOWNLOAD_BACKUP_INIT = 'GET_DOWNLOAD_BACKUP_INIT',
  GET_DOWNLOAD_BACKUP_SUCCESS = 'GET_DOWNLOAD_BACKUP_SUCCESS',
  GET_DOWNLOAD_BACKUP_FAILURE = 'GET_DOWNLOAD_BACKUP_FAILURE',
  ////
  GET_RESTORE_BACKUP_STATUS_INIT = 'GET_RESTORE_BACKUP_STATUS_INIT',
  GET_RESTORE_BACKUP_STATUS_SUCCESS = 'GET_RESTORE_BACKUP_STATUS_SUCCESS',
  GET_RESTORE_BACKUP_STATUS_FAILURE = 'GET_RESTORE_BACKUP_STATUS_FAILURE',
}
export enum Updates {
  GET_UPDATES_INIT = 'GET_UPDATES_INIT',
  GET_UPDATES_SUCCESS = 'GET_UPDATES_SUCCESS',
  GET_UPDATES_FAILURE = 'GET_UPDATES_FAILURE',
  ///
  GET_UPDATES_WEBSITE_INIT = 'GET_UPDATES_WEBSITE_INIT',
  GET_UPDATES_WEBSITE_SUCCESS = 'GET_UPDATES_WEBSITE_SUCCESS',
  GET_UPDATES_WEBSITE_FAILURE = 'GET_UPDATES_WEBSITE_FAILURE',
  ///
  GET_UPDATES_PARTNERS_INIT = 'GET_UPDATES_PARTNERS_INIT',
  GET_UPDATES_PARTNERS_SUCCESS = 'GET_UPDATES_PARTNERS_SUCCESS',
  GET_UPDATES_PARTNERS_FAILURE = 'GET_UPDATES_PARTNERS_FAILURE',
  ///
  GET_AWAITING_UPDATES_INIT = 'GET_AWAITING_UPDATES_INIT',
  GET_AWAITING_UPDATES_SUCCESS = 'GET_AWAITING_UPDATES_SUCCESS',
  GET_AWAITING_UPDATES_FAILURE = 'GET_AWAITING_UPDATES_FAILURE',
  
}

export enum Payment {
  GET_PAYMENT_INIT = 'GET_PAYMENT_INIT',
  GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS',
  GET_PAYMENT_FAILURE = 'GET_PAYMENT_FAILURE',
  ////
  GET_STRIPE_PRICE_INIT = 'GET_STRIPE_PRICE_INIT',
  GET_STRIPE_PRICE_SUCCESS = 'GET_STRIPE_PRICE_SUCCESS',
  GET_STRIPE_PRICE_FAILURE = 'GET_STRIPE_PRICE_FAILURE',
  ////
}

export enum Packet {
  BASIC = 'basic',
  PREMIUM = 'premium',
  PRO = 'pro',
}
