import { AddWebsite } from '../EnumTypes/ActionEnumTypes';

const success = () => ({
  type: AddWebsite.ADD_WEBSITE_FORM_RESET,
  payload: {},
});

const addWebsiteFormReset = () => async (dispatch) => {
  try {
    dispatch(success());
  } catch (err: any) {
    console.log(err);
  }
};

export default addWebsiteFormReset;
