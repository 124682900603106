import React, { useEffect } from 'react';
import { MainType } from './Container';
import { Button } from 'Shared/components';

import { Route } from 'react-router-dom';
import { Account, Company, CreditCard, FTP, Website } from './forms';
import ADD_WEB_SITE_ROUTES from './AddWebsiteRoutes';
import { ROUTES, SVG_TYPE } from 'Shared/enums';

function AddWebsite(props: MainType) {
  const {
    history,
    authorizedUser,
    formData,
    actions: { changeMaxStep, getAuthorizedUser, addWebsiteFormChange, addWebsiteFormReset },
    intl: { formatMessage },
  } = props;
  const website = history.location.state?.website;
  const query = new URLSearchParams(history.location.search);
  const clientId = query.get('clientId');
  const websiteId = query.get('id');
  const withoutWebsiteForm = history.location.withoutWebsiteStep || !!websiteId;
  const withoutAccountForm = history.location.withoutAccountForm || authorizedUser;
  const withoutCompanyForm = history.location.withoutCompanyForm || (authorizedUser?.company?.address && authorizedUser?.company?.vat_id);
  const withoutPaymentForm =
    history.location.withoutPaymentForm ||
    (authorizedUser?.company?.subscription_uid && website?.subscription_uid) ||
    (!website && authorizedUser?.company?.subscription_uid);
  const previousPath = history.location.previousPath;

  const calcMaxStep = () => {
    let maxStep = 5;
    if (withoutWebsiteForm) {
      maxStep--;
    }
    if (withoutAccountForm && !formData?.email) {
      maxStep--;
    }
    if (withoutCompanyForm) {
      maxStep--;
    }
    if (withoutPaymentForm) {
      maxStep--;
    }
    return maxStep;
  };
  useEffect(() => {
    getAuthorizedUser();
    const dataFromStorage = sessionStorage.getItem('addWebsiteForm');
    if (dataFromStorage && !websiteId) {
      addWebsiteFormChange(JSON.parse(dataFromStorage));
    }
    if (websiteId) {
      addWebsiteFormReset();
    }
  }, []);

  useEffect(() => {
    changeMaxStep(calcMaxStep());
  }, [authorizedUser]);

  const handleBack = () => {
    const currentPath = history.location.pathname;
    const currentStep = currentPath.split('/').pop();
    if (currentStep === 'website' || currentStep === 'account') {
      if (withoutWebsiteForm) {
        history.push(`${ROUTES.WEBSITE_CHECKER}?url=${history.location.url}`);
      } else if (history?.location?.state?.from) {
        history.push(history.location.state.from);
      } else {
        previousPath ? history.push(previousPath) : history.push({ pathname: `${ROUTES.HOME_PAGE}`, packages: 'basic' });
      }
    } else {
      if (!!clientId) {
        history.push(ROUTES.PARTNER + '?clientId=' + clientId);
        return;
      }
      if (history?.location?.state?.from) {
        history.push(history.location.state.from);
        return;
      }
      if (authorizedUser.role === 'admin') {
        history.push(ROUTES.ALL_WEBISTE);
        return;
      }

      history.push(ROUTES.MY_WEBSITES);
    }
  };
  return (
    <div className="add-website-form">
      <Button
        classNames="add-website-form__button add-website-form__button--desktop btn__black-stroke"
        label={formatMessage({ id: 'global.close' })}
        onClick={() => handleBack()}
        iconImgSvg={SVG_TYPE.CLOSE}
      />
      <div className="add-website-form__modal">
        <div className="add-website-form__form">
          <Button
            classNames="add-website-form__button add-website-form__button--mobile btn__black-stroke"
            label={formatMessage({ id: 'global.close' })}
            onClick={() => handleBack()}
            iconImgSvg={SVG_TYPE.CLOSE}
          />
          <Route path={ADD_WEB_SITE_ROUTES.WEBSITE} withoutWebsiteForm={withoutWebsiteForm} maxStep={calcMaxStep()} component={Website} />
          <Route path={ADD_WEB_SITE_ROUTES.ACCOUNT} component={Account} maxStep={calcMaxStep()} withoutAccountForm={withoutAccountForm} />
          <Route path={ADD_WEB_SITE_ROUTES.COMPANY} component={Company} maxStep={calcMaxStep()} />
          <Route path={ADD_WEB_SITE_ROUTES.CREDIT_CARD} component={CreditCard} maxStep={calcMaxStep()} withoutPayment={withoutPaymentForm} />
          <Route path={ADD_WEB_SITE_ROUTES.FTP} component={FTP} maxStep={calcMaxStep()} />
        </div>
      </div>
    </div>
  );
}

export default AddWebsite;
