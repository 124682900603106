const POINTS = (details) => [
  {
    id: 1,
    label: 'Poprawność danych do FTP',
    name: 'check_connection',
    methodName: 'checkConnection',
    isTested: details['check_connection'] && true,
    // isInProgress: details['check_connection'] && details['check_connection']['status'] === 'pending',
    isFailed: details['check_connection'] && !details['check_connection']['is_suceeded'],
    errorMessage: details['check_connection'] && details['check_connection']['error_message'] && details['check_connection']['error_message'],
  },
  {
    id: 2,
    label: 'Uprawnienia do tworzenia pliku na FTP',
    name: 'check_creating_file',
    methodName: 'checkCreatingFile',
    isTested: details['check_creating_file'] && true,
    // isInProgress: details['check_creating_file'] && details['check_creating_file']['status'] === 'pending',
    isFailed: details['check_creating_file'] && !details['check_creating_file']['is_suceeded'],
    errorMessage:
      details['check_creating_file'] && details['check_creating_file']['error_message'] && details['check_creating_file']['error_message'],
  },
  {
    id: 3,
    label: 'Uprawnienia do edycji pliku na FTP',
    name: 'check_perms',
    methodName: 'checkPerms',
    isTested: details['check_perms'] && true,
    // isInProgress: details['check_perms'] && details['check_perms']['status'] === 'pending',
    isFailed: details['check_perms'] && !details['check_perms']['is_suceeded'],
    errorMessage: details['check_perms'] && details['check_perms']['error_message'] && details['check_perms']['error_message'],
  },
  {
    id: 4,
    label: 'Uprawnienia do usunięcia pliku na FTP',
    name: 'check_deleting_file',
    methodName: 'checkDeletingFile',
    isTested: details['check_deleting_file'] && true,
    // isInProgress: details['check_deleting_file'] && details['check_deleting_file']['status'] === 'pending',
    isFailed: details['check_deleting_file'] && !details['check_deleting_file']['is_suceeded'],
    errorMessage:
      details['check_deleting_file'] && details['check_deleting_file']['error_message'] && details['check_deleting_file']['error_message'],
  },
  {
    id: 5,
    label: 'Obecność danych konfiguracyjnych w wp-config',
    name: 'check_corgi_config',
    methodName: 'checkCorgiConfig',
    isTested: details['check_corgi_config'] && true,
    // isInProgress: details['check_corgi_config'] && details['check_corgi_config']['status'] === 'pending',
    isFailed: details['check_corgi_config'] && !details['check_corgi_config']['is_suceeded'],
    errorMessage: details['check_corgi_config'] && details['check_corgi_config']['error_message'] && details['check_corgi_config']['error_message'],
  },
  {
    id: 6,
    label: 'Lokalizacja pluginu Corgi',
    name: 'check_corgi_path',
    methodName: 'checkCorgiPath',
    isTested: details['check_corgi_path'] && true,
    // isInProgress: details['check_corgi_path'] && details['check_corgi_path']['status'] === 'pending',
    isFailed: details['check_corgi_path'] && !details['check_corgi_path']['is_suceeded'],
    errorMessage: details['check_corgi_path'] && details['check_corgi_path']['error_message'] && details['check_corgi_path']['error_message'],
  },
  {
    id: 7,
    label: 'Zgodność lokalizacji Wordpressa',
    name: 'check_wordpress_path',
    methodName: 'checkWordpressPath',
    isTested: details['check_wordpress_path'] && true,
    // isInProgress: details['check_wordpress_path'] && details['check_wordpress_path']['status'] === 'pending',
    isFailed: details['check_wordpress_path'] && !details['check_wordpress_path']['is_suceeded'],
    errorMessage:
      details['check_wordpress_path'] && details['check_wordpress_path']['error_message'] && details['check_wordpress_path']['error_message'],
  },
  {
    id: 8,
    label: 'Połączenie z bazą danych WP',
    name: 'check_db_connection',
    methodName: 'checkDbConnection',
    isTested: details['check_db_connection'] && true,
    // isInProgress: details['check_db_connection'] && details['check_db_connection']['status'] === 'pending',
    isFailed: details['check_db_connection'] && !details['check_db_connection']['is_suceeded'],
    errorMessage:
      details['check_db_connection'] && details['check_db_connection']['error_message'] && details['check_db_connection']['error_message'],
  },
  {
    id: 9,
    label: 'Bridge_health',
    name: 'check_bridge_health',
    methodName: 'checkBridgeHealth',
    isTested: details['check_bridge_health'] && true,
    // isInProgress: details['check_bridge_health'] && details['check_bridge_health']['status'] === 'pending',
    isFailed: details['check_bridge_health'] && !details['check_bridge_health']['is_suceeded'],
    errorMessage:
      details['check_bridge_health'] && details['check_bridge_health']['error_message'] && details['check_bridge_health']['error_message'],
  },
  {
    id: 10,
    label: 'Dostępność lokalizacji tworzenia backupu',
    name: 'check_creating_backup',
    methodName: 'checkCreatingBackup',
    isTested: details['check_creating_backup'] && true,
    // isInProgress: details['check_creating_backup'] && details['check_creating_backup']['status'] === 'pending',
    isFailed: details['check_creating_backup'] && !details['check_creating_backup']['is_suceeded'],
    errorMessage:
      details['check_creating_backup'] && details['check_creating_backup']['error_message'] && details['check_creating_backup']['error_message'],
  },
  {
    id: 11,
    label: 'Awaryjne połączenie z bazą danych',
    name: 'check_emergency_db_connection',
    methodName: 'checkEmergencyDBConnection',
    isTested: details['check_emergency_db_connection'] && true,
    // isInProgress: details['check_creating_backup'] && details['check_creating_backup']['status'] === 'pending',
    isFailed: details['check_emergency_db_connection'] && !details['check_emergency_db_connection']['is_suceeded'],
    errorMessage:
      details['check_emergency_db_connection'] && details['check_emergency_db_connection']['error_message'] && details['check_emergency_db_connection']['error_message'],
  },
];

const POINTS_BASIC_INITIAL_STATE = {
  check_connection: false,
  check_db_connection: false,
  check_creating_backup: false,
  check_creating_file: false,
  check_deleting_file: false,
  check_perms: false,
  check_corgi_config: false,
  check_wordpress_path: false,
  check_corgi_path: false,
  check_bridge_health: false,
  check_emergency_db_connection: false,
};

const METHODS_LIST = [
  'checkConnection',
  'checkCreatingFile',
  'checkPerms',
  'checkDeletingFile',
  'checkCorgiConfig',
  'checkCorgiPath',
  'checkWordpressPath',
  'checkDbConnection',
  'checkBridgeHealth',
  'checkCreatingBackup',
  'checkEmergencyDBConnection',
];

const changeState = (state, caller, optionValue, name) =>
  caller({
    ...state,
    [name]: !optionValue,
  });

const chooseAll = (state) => {
  Object.keys(state).forEach((key) => {
    state[key] = true;
  });
};

const uncheckAll = (state) => {
  Object.keys(state).forEach((key) => {
    state[key] = false;
  });
};

export { POINTS, POINTS_BASIC_INITIAL_STATE, changeState, chooseAll, METHODS_LIST, uncheckAll };
